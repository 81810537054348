import { useaxios } from "../../../utils/useaxios";
export default (user_name, user_password, user_role, discount_attemps, discount_range) => recaller => setrecaller => initDispatch => clear => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    initDispatch({ type: 'loadingTrue' });
    useaxios('user/create.php', { user_name, user_password, user_role, discount_attemps, discount_range }).then((res) => {
        if (res.status == "ok") {
            setrecaller(recaller + 1);
        } else {
            clear();
        }
        // setloading(false);
    });
}