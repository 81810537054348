import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes
} from "react-router-dom";
import React, { useContext, useState } from 'react';
import './App.css';
import Loading from './component/loading';
import { GlobalContext } from "./context/provider";
import Login from "./component/login";
import Home from "./component/home";
import { BiLogOut } from "react-icons/bi";
import { AiOutlineMenu } from "react-icons/ai";
import Menu from "./component/menu";
import User from "./pages/user/user";
import Expensetype from "./pages/expensetype/expensetype";
import Expense from "./pages/expense/expense";
import Category from "./pages/category/category";
import Company from "./pages/company/company";
import Item from "./pages/item/item";
import Buy from "./pages/buy/buy";
import Sell from "./pages/sell/sell";
import Buyinvoice from "./pages/buyinvoice/buyinvoice";
import Report from "./pages/report/report";
import Broke from "./pages/broke/broke";
import Barcode from "./pages/barcode/barcode";
import './component/css/onprintcss.css';
import Returnbybarcode from "./pages/returnbybarcode/returnbybarcode";
import Return_item_company from "./pages/return_item_company/return_item_company";
import Deleteditem from "./pages/deleteditem/deleteditem";


function App() {
  const { initState, initDispatch, clear } = useContext(GlobalContext);
  const [showMenu, setshowMenu] = useState(false);
  return (
    <Router>
      {initState.loading ? <Loading /> :
        initState.islogin && initState.token != null ?
          <div className="w-full h-screen flex overflow-x-hidden" dir="rtl">
            <div className={showMenu ? "fixed md:hidden w-full md:w-96 md:h-full border-r-[1px] bg-white border-gray-400 z-50" : "hidden md:block md:w-96 md:h-full border-r-[1px] border-gray-400 "}>
              <Menu showMenu={showMenu} setshowMenu={setshowMenu} />
            </div>
            <div className="w-full h-full z-0">
              <div className="w-full h-16 border-b-2 flex md:justify-end justify-between items-center px-10 print_hide">
                <AiOutlineMenu size={30} className="md:hidden cursor-pointer" onClick={() => { setshowMenu(!showMenu) }} />
                <BiLogOut size={30} className="cursor-pointer" onClick={() => { clear() }} />
              </div>
              {initState.role == "admin" ?
                <Routes>
                  {/* <Route path="/" element={<Home />} /> */}
                  <Route path="/user" element={<User />} />
                  <Route path="/expensetype" element={<Expensetype />} />
                  <Route path="/expense" element={<Expense />} />
                  <Route path="/returnitemtocompany" element={<Return_item_company />} />
                  <Route path="/broke" element={<Broke />} />
                  <Route path="/category" element={<Category />} />
                  <Route path="/company" element={<Company />} />
                  <Route path="/item" element={<Item />} />
                  <Route path="/removeditem" element={<Deleteditem />} />
                  <Route path="/buy" element={<Buy />} />
                  <Route path="/" element={<Sell />} />
                  <Route path="/returnbybarcode" element={<Returnbybarcode />} />
                  <Route path="/buyinvoice" element={<Buyinvoice />} />
                  <Route path="/report" element={<Report />} />
                  <Route path="/barcode" element={<Barcode />} />
                </Routes> : initState.role == "casher" ?
                  <Routes>
                    {/* <Route path="/" element={<Home />} /> */}
                    <Route path="/" element={<Sell />} />
                    <Route path="/returnbybarcode" element={<Returnbybarcode />} />
                  </Routes>
                  :
                  <Routes>
                    {/* <Route path="/" element={<Home />} /> */}
                    <Route path="/" element={<Report />} />
                    <Route path="/report" element={<Report />} />
                  </Routes>
              }
            </div>
          </div>
          : <Login />
      }
    </Router>
  );
}

export default App;
