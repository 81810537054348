import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import numberbeautify from "../../utils/numberbeautify";
import Editbrokemodal from "./editbrokemodal";

const Brokelist = () => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

    const [damage_id,setdamage_id]=useState();
    const [barcode,setbarcode]=useState();
    const [damage_qty,setdamage_qty]=useState();

    const [show,setshow]=useState(false);
  return (
    <div>
        <Editbrokemodal show={show} setshow={setshow} damage_id={damage_id} barcode={barcode} setbarcode={setbarcode} damage_qty={damage_qty} setdamage_qty={setdamage_qty} />
        <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    ناوی کاڵا
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    باڕکۆد
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    نرخی کاڵا
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    بڕی خەسار
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    بەروار
                    </th>
                  </tr>
                </thead>
                <tbody>
                    {initState.broke.map((lis)=>(
                        <tr key={lis.damage_id} className="cursor-pointer" onClick={()=>{
                            setdamage_id(lis.damage_id);
                            setbarcode(lis.item_barcode);
                            setdamage_qty(lis.damage_qty);
                            setshow(true);
                        }}>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                {lis.item_name}
                                </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                {lis.item_barcode}
                                </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                {lis.item_price}
                                </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                {lis.damage_qty}
                                </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                {lis.damage_date}
                                </p>
                            </td>
                        </tr>
                    ))}
                </tbody>
                </table>
                </div>
                </div>
                </div>
                </div>
    </div>
  )
}

export default Brokelist