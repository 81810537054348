import { useaxios } from "../../../utils/useaxios";
export default (from, to) => recaller => setrecaller => initDispatch => clear => settype => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // initDispatch({ type: 'loadingTrue' });
    useaxios('report/expense.php', { from, to }).then((res) => {
        if (res.status == "ok") {
            initDispatch({ type: 'setexpense_report', payload: res.data });
            settype('expense');
            // setrecaller(recaller + 1);
        } else {
            // clear();
        }
        // setloading(false);
    });
}