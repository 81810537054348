import React, { useState, useContext } from "react";
import './sellinvoiceprint.scss';
import qrcode from '../../assets/images/qrcode.png';
import numberbeautify from "../../utils/numberbeautify";

const Sellitemprint = props => {
  var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()+" "+today.getHours()+":"+today.getMinutes()+":"+today.getSeconds();
  return (
    <div className='ccontainerr' dir='ltr'>
    <div id="invoice-POS" dir='ltr'>
      
      <center id="top">
        <div className="logo"><img src={qrcode} /></div>
        <div className="info"> 
          <h2>Prestige</h2>
        </div>
      </center>
      
      <div id="mid">
        <div className="info">
          <h2>Invoice Info</h2>
          <p> 
              Invoice no : {props.invoiceno}<br />
              Date   : {today}<br />
              Casher   : {props.username}<br />
          </p>
        </div>
      </div>
      <div id="bot">
                    <div id="table">
                          <table>
                              <tr className="tabletitle">
                                  <td className="item"><h2>Item</h2></td>
                                  <td className="item"><h2>Qty</h2></td>
                                  <td className="item"><h2>Price</h2></td>
                                  <td className="item"><h2>Sub Total</h2></td>
                              </tr>
                              {props.items.map((lis)=>(
                              <tr className="service">
                                  <td className="tableitem"><p className="itemtext">{lis.item_name}</p></td>
                                  <td className="tableitem"><p className="itemtext">{lis.item_qty}</p></td>
                                  <td className="tableitem"><p className="itemtext">{numberbeautify(lis.item_sell_price)}</p></td>
                                  <td className="tableitem"><p className="itemtext">{numberbeautify(Number(lis.item_qty)*Number(lis.item_sell_price))}</p></td>
                              </tr>
                              ))}
  
                              <tr className="tabletitle">
                                  <td></td>
                                  <td className="Rate"><h2>Total</h2></td>
                                  <td></td>
                                  <td className="payment"><h2>{numberbeautify(props.items.length>0?props.items.reduce((a, v) => a = Number(a) + (Number(v.item_qty)*Number(v.item_sell_price)), 0):0)}</h2></td>
                              </tr>
  
                          </table>
                      </div>
  
                      <div id="legalcopy">
                          <p className="legal"><strong>Thank you for your Choice !</strong>  
                          </p>
                      </div>
  
                  </div>
    </div>
  </div>
  )
}

export default Sellitemprint