import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/company/createaction";
import Companylist from "./companylist";
const Company = () => {
  const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

  const [name, setname] = useState();
  const [address, setaddress] = useState();
  const [phone, setphone] = useState();
  const [loan, setloan] = useState();
  const [error, seterror] = useState(false);
  const [error1, seterror1] = useState(false);
  const [error2, seterror2] = useState(false);
  const [error3, seterror3] = useState(false);

  return (
    <div>
      <div className="w-full h-20 flex justify-center items-center text-3xl">
        کۆمپانیای دابینکار
      </div>
      <div className="w-full flex">
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            ناوی کۆمپانیا
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="ناو"
            value={name}
            onChange={(val) => setname(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              error1
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            ناونیشان
          </label>
          <input
            className={
              error1
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="ناونیشان"
            value={address}
            onChange={(val) => setaddress(val.target.value)}
            onFocus={() => seterror1(false)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              error2
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            ژمارەی تەلەفۆن
          </label>
          <input
            className={
              error2
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="ژمارەی تەلەفۆن"
            value={phone}
            onChange={(val) => setphone(val.target.value)}
            onFocus={() => seterror2(false)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              error3
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            قەرزی بنچینەی
          </label>
          <input
            className={
              error3
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="number"
            placeholder="3.14"
            value={loan}
            onChange={(val) => setloan(val.target.value)}
            onFocus={() => seterror3(false)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 mt-1">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={() => {
              if (name == "" || !name) {
                seterror(true);
                return false;
              }
              if (address == "" || !address) {
                seterror1(true);
                return false;
              }
              if (phone == "" || !phone) {
                seterror2(true);
                return false;
              }
              if (loan < 0 || !name) {
                seterror3(true);
                return false;
              }
              createaction(
                name,
                address,
                phone,
                loan,
                initState.token.id
              )(recaller)(setrecaller)(initDispatch)(clear);
            }}
          >
            زیادکردن
          </button>
        </div>
      </div>
      <Companylist />
    </div>
  );
};

export default Company;
