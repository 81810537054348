import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import numberbeautify from "../../utils/numberbeautify";
import Edititemmodal from "./edititemmodal";

const Itemlist = () => {
  const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

  const [item_id, setitem_id] = useState();
  const [item_name, setitem_name] = useState();
  const [item_price, setitem_price] = useState();
  const [item_barcode, setitem_barcode] = useState();
  const [item_min_notification, setitem_min_notification] = useState();
  const [item_min_sell_price, setitem_min_sell_price] = useState();
  const [company_id, setcompany_id] = useState();
  const [category_id, setcategory_id] = useState();
  const [show, setshow] = useState(false);

  return (
    <div>
      <Edititemmodal
        item_id={item_id}
        item_name={item_name}
        setitem_name={setitem_name}
        item_price={item_price}
        setitem_price={setitem_price}
        item_barcode={item_barcode}
        setitem_barcode={setitem_barcode}
        item_min_notification={item_min_notification}
        setitem_min_notification={setitem_min_notification}
        item_min_sell_price={item_min_sell_price}
        setitem_min_sell_price={setitem_min_sell_price}
        company_id={company_id}
        setcompany_id={setcompany_id}
        category_id={category_id}
        setcategory_id={setcategory_id}
        show={show}
        setshow={setshow}
      />
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    اسم صنف
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      باڕکۆد
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    سعر
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    عدد المتبقي
                    </th>
                    {/* <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      بەرواری بەسەرچون
                    </th> */}
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      نوع
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    تبديل
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {initState.item.map((lis) => (
                    <tr
                      key={lis.item_id}
                      className={"bg-white"}
                    >
                      <td className="px-5 py-5 border-b border-gray-200 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.item_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200  text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.item_barcode}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200  text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {numberbeautify(lis.item_price)}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200  text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.item_qty}
                        </p>
                      </td>
                      {/* <td className="px-5 py-5 border-b border-gray-200  text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.item_exp_date}
                        </p>
                      </td> */}
                      <td className="px-5 py-5 border-b border-gray-200  text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.category_name}
                        </p>
                      </td>
                      <td
                        className="px-5 py-5 border-b border-gray-200  text-sm cursor-pointer"
                        onClick={() => {
                          setitem_id(lis.item_id);
                          setitem_name(lis.item_name);
                          setitem_price(lis.item_price);
                          setitem_barcode(lis.item_barcode);
                          setitem_min_notification(lis.item_min_notification);
                          setitem_min_sell_price(lis.item_min_sell_price);
                          setcompany_id(lis.company_id);
                          setcategory_id(lis.category_id);
                          setshow(true);
                        }}
                      >
                        <span
                          className={
                            "relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight"
                          }
                        >
                          <span
                            aria-hidden
                            className={
                              "absolute inset-0 bg-orange-400 opacity-50 rounded-md"
                            }
                          ></span>
                          <span className="relative">تبديل</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Itemlist;
