import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import numberbeautify from "../../utils/numberbeautify";
import Expensedetailmodal from "./expensedetailmodal";

const Expensetable = () => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

    const [expense,setexpense]=useState();
    const [show,setshow]=useState(false);
  return (
    <div>
        <Expensedetailmodal show={show} setshow={setshow} expense={expense} />
        <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    نوع المصرف
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    المقدار المنفق
                    </th>
                  </tr>
                </thead>
                <tbody>
                    {initState.expense_report.map((lis)=>(
                    <tr key={lis.expense_type_id} className="cursor-pointer" onClick={()=>{setexpense(lis.expense);setshow(true)}}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.expense_type_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {numberbeautify(lis.expense.reduce((a, v) => a = Number(a) + Number(v.expense_amount), 0))}
                        </p>
                      </td>
                    </tr>
                    ))}
                    </tbody>
                    </table>
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
  )
}

export default Expensetable