import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/item/createaction";

const Additemmodal = (props) => {
  const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

  const [item_name, setitem_name] = useState();
  const [item_barcode, setitem_barcode] = useState();
  const [item_price, setitem_price] = useState();
  const [item_min_notification, setitem_min_notification] = useState();
  const [item_min_sell_price, setitem_min_sell_price] = useState(0);
  const [category_id, setcategory_id] = useState("null");
  const [company_id, setcompany_id] = useState("null");

  const [error, seterror] = useState(false);
  const [error1, seterror1] = useState(false);
  const [error2, seterror2] = useState(false);
  const [error3, seterror3] = useState(false);
  const [error4, seterror4] = useState(false);
  const [error5, seterror5] = useState(false);
  const [error6, seterror6] = useState(false);

  return (
    <>
      {props.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto md:w-2/3 lg:w-1/2">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl w-full uppercase font-semibold">
                  زيادة صنف
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setshow(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      X
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-wrap w-full">
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className={
                          error
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        اسم صنف
                      </label>
                      <input
                        className={
                          error
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder="اسم صنف"
                        value={item_name}
                        onChange={(val) => setitem_name(val.target.value)}
                        onFocus={() => seterror(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className={
                          error1
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        باڕکۆد
                      </label>
                      <input
                        className={
                          error1
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder="باڕکۆد"
                        value={item_barcode}
                        onChange={(val) => setitem_barcode(val.target.value)}
                        onFocus={() => seterror1(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-3 my-2">
                      <label
                        className={
                          error2
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        سعر
                      </label>
                      <input
                        className={
                          error2
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="number"
                        placeholder="سعر"
                        value={item_price}
                        onChange={(val) => setitem_price(val.target.value)}
                        onFocus={() => seterror2(false)}
                      />
                    </div>
                    {/* <div className="w-full md:w-1/2 px-3">
                      <label
                        className={
                          error6
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        کەمترین نرخی فرۆشتن
                      </label>
                      <input
                        className={
                          error6
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="number"
                        placeholder="کەمترین نرخی فرۆشتن"
                        value={item_min_sell_price}
                        onChange={(val) =>
                          setitem_min_sell_price(val.target.value)
                        }
                        onFocus={() => seterror6(false)}
                      />
                    </div> */}
                    <div className="w-full md:w-1/2 px-3 my-2">
                      <label
                        className={
                          error3
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        تحذير المتبقي من هذا الصنف
                      </label>
                      <input
                        className={
                          error3
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="number"
                        placeholder="تحذير المتبقي من هذا الصنف"
                        value={item_min_notification}
                        onChange={(val) =>
                          setitem_min_notification(val.target.value)
                        }
                        onFocus={() => seterror3(false)}
                      />
                    </div>
                    <div className="w-full md:w-full px-3 mt-1">
                      <label
                        for="catee"
                        className={
                          error5
                            ? "block mb-2 text-sm font-medium text-red-900"
                            : "block mb-2 text-sm font-medium text-gray-900"
                        }
                      >
                        نوع صنف
                      </label>
                      <select
                        id="catee"
                        className={
                          error5
                            ? "bg-gray-50 border border-red-300 text-red-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
                            : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
                        }
                        value={category_id}
                        onChange={(val) => {
                          setcategory_id(val.target.value);
                          seterror5(false);
                        }}
                      >
                        <option value="null">نوع</option>
                        {initState.category.map((lis) => (
                          <option value={lis.category_id}>
                            {lis.category_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      props.setshow(false);
                    }}
                  >
                    أغلاق
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (item_name == "" || !item_name) {
                        seterror(true);
                        return false;
                      }
                      if (item_barcode == "" || !item_barcode) {
                        seterror1(true);
                        return false;
                      }
                      if (item_price < 0 || !item_price) {
                        seterror2(true);
                        return false;
                      }
                      if (item_min_notification < 0 || !item_min_notification) {
                        seterror3(true);
                        return false;
                      }
                      if (category_id == "null" || !category_id) {
                        seterror5(true);
                        return false;
                      }
                      createaction(
                        item_name,
                        item_barcode,
                        item_price,
                        item_min_notification,
                        item_min_sell_price,
                        category_id
                      )(recaller)(setrecaller)(initDispatch)(clear);
                    }}
                  >
                                زيادة صنف
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Additemmodal;
