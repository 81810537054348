import { useaxios } from "../../../utils/useaxios";
export default (text) => recaller => setrecaller => items => setitems => settext => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('item/getitem.php', { text }).then((res) => {
        settext('');
        if (res.status == "ok") {
            if (items.find((lis) => lis.item_id == res.data[0].item_id)) {
                setitems(items.map((lis) => lis.item_id == res.data[0].item_id ? { item_id: lis.item_id, item_name: lis.item_name, item_barcode: lis.item_barcode, company_invoice_item_qty: Number(lis.company_invoice_item_qty) + 1, company_invoice_item_price: lis.company_invoice_item_price, company_invoice_item_exp_date: lis.company_invoice_item_exp_date } : lis));
            } else {
                setitems([...items, { item_id: res.data[0].item_id, item_name: res.data[0].item_name, item_barcode: res.data[0].item_barcode, company_invoice_item_qty: 0, company_invoice_item_price: 0, company_invoice_item_exp_date: today }]);
            }
        } else {
            window.alert('هیچ کاڵایەک نەدۆزرایەوە');
        }
    });
}