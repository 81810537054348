import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import numberbeautify from "../../utils/numberbeautify";
import Editexpensemodal from "./editexpensemodal";
import moment from "moment/moment";
const Expenselist = () => {
  const { initState, recaller, setrecaller, clear, initDispatch } =
    useContext(GlobalContext);

  const [expense_id, setexpense_id] = useState();
  const [expense_amount, setexpense_amount] = useState();
  const [expense_type_id, setexpense_type_id] = useState();
  const [expense_note, setexpense_note] = useState();
  const [expense_date, setexpense_date] = useState();
  const [show, setshow] = useState();
  return (
    <div>
      <Editexpensemodal
        show={show}
        setshow={setshow}
        expense_id={expense_id}
        expense_amount={expense_amount}
        setexpense_amount={setexpense_amount}
        expense_type_id={expense_type_id}
        setexpense_type_id={setexpense_type_id}
        expense_note={expense_note}
        expense_date={expense_date}
        setexpense_date={setexpense_date}
        setexpense_note={setexpense_note}
      />
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    نوع المصاريف
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    المصاريف
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    تاريخ
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      یوزەر
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    الملاحظة
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    تغير
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {initState.expense.map((lis) => (
                    <tr key={lis.expense_id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.expense_type_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {numberbeautify(lis.expense_amount)}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {moment(lis.expense_date).format('YYYY-MM-DD')}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.user_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.expense_note}
                        </p>
                      </td>
                      <td
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm cursor-pointer"
                        onClick={() => {
                          setexpense_id(lis.expense_id);
                          setexpense_amount(lis.expense_amount);
                          setexpense_type_id(lis.expense_type_id);
                          setexpense_note(lis.expense_note);
                          setexpense_date(moment(lis.expense_date).format('YYYY-MM-DD'));
                          setshow(true);
                        }}
                      >
                        <span
                          className={
                            "relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight"
                          }
                        >
                          <span
                            aria-hidden
                            className={
                              "absolute inset-0 bg-orange-400 opacity-50 rounded-md"
                            }
                          ></span>
                          <span className="relative">تغير</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expenselist;
