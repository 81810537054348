import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import numberbeautify from "../../utils/numberbeautify";

const Summarytable = () => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

    var total_income=initState.summary_report_sell_array.reduce((a, v) => a = Number(a) + Number(v.item_sell_price), 0);

  return (
    <div>
        <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <tr>
                    <th className="px-5 py-3 border-b border-gray-200 bg-green-300 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">فرۆش - بڕی کاڵای فرۆشراو ، کۆی فرۆش ، قازانج</th>
                    <td className="px-5 py-5 border-b border-gray-200 bg-green-300 text-sm">{initState.summary_report_sell_array.reduce((a, v) => a = Number(a) + Number(v.item_sell_qty), 0)}</td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-green-300 text-sm">{numberbeautify(initState.summary_report_sell_array.reduce((a, v) => a = Number(a) + Number(v.item_static_sell_price), 0))}</td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-green-300 text-sm">{numberbeautify(initState.summary_report_sell_array.reduce((a, v) => a = Number(a) + Number(v.item_sell_price), 0))}</td>
                </tr>
                <tr>
                    <th className="px-5 py-3 border-b border-gray-200 bg-green-300 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">کاڵای گەڕاوە بۆ کۆمپانیا -  بڕی گەڕاوە ، کۆی پارە ، -</th>
                    <td className="px-5 py-5 border-b border-gray-200 bg-green-300 text-sm">{initState.summary_report_return_item_company_qty}</td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-green-300 text-sm">{numberbeautify(initState.summary_report_return_item_company_amount)}</td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-green-300 text-sm">-</td>
                </tr>
                <tr>
                    <th className="px-5 py-3 border-b border-gray-200 bg-red-300 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">خەرجی - بڕی خەرجی ، - ، -</th>
                    <td className="px-5 py-5 border-b border-gray-200 bg-red-300 text-sm">{numberbeautify(initState.summary_report_expense_amount)}</td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-red-300 text-sm">-</td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-red-300 text-sm">-</td>
                </tr>
                <tr>
                    <th className="px-5 py-3 border-b border-gray-200 bg-red-300 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">خەسار - بڕی کاڵای خەسار ، کۆی پارەی خەسار ، -</th>
                    <td className="px-5 py-5 border-b border-gray-200 bg-red-300 text-sm">{initState.summary_report_damage_qty}</td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-red-300 text-sm">{numberbeautify(initState.summary_report_damage_amount)}</td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-red-300 text-sm">-</td>
                </tr>
                <tr>
                    <th className="px-5 py-3 border-b border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">کۆی ماوە</th>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">{numberbeautify(Number(Number(total_income)+Number(initState.summary_report_return_item_company_amount))-(Number(initState.summary_report_expense_amount)+Number(initState.summary_report_damage_amount)))}</td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">-</td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">-</td>
                </tr>
              </table>
              </div>
              </div>
              </div>
              </div>
    </div>
  )
}

export default Summarytable