import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/user/createaction";

const Addusermodal = (props) => {
    const { initState, recaller, setrecaller, clear, initDispatch } =
    useContext(GlobalContext);

    const [username, setusername] = useState();
    const [password, setpassword] = useState();
    const [discount_attemps, setdiscount_attemps] = useState(100);
    const [discount_range, setdiscount_range] = useState(100);
    const [role, setrole] = useState("admin");
    const [show,setshow]=useState(false);
  
    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
  return (
    <>
      {props.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto md:w-2/3 lg:w-1/2">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl w-full uppercase font-semibold">
                  زيادة المستخدم
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setshow(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      X
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-wrap w-full">
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className={
                          error
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        اسم
                      </label>
                      <input
                        className={
                          error
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder="Pop Cake"
                        value={username}
                        onChange={(val) => setusername(val.target.value)}
                        onFocus={() => seterror(false)}
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className={
                          error1
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        الكلمة السرية
                      </label>
                      <input
                        className={
                          error1
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder="*******"
                        value={password}
                        onChange={(val) =>
                          setpassword(val.target.value)
                        }
                        onFocus={() => seterror1(false)}
                      />
                    </div>
                    <div className={role=='casher'?"w-full md:w-1/2 px-3":"hidden"}>
                      <label
                        className={
                        "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        تخفيظات الشهرية
                      </label>
                      <input
                        className={
                          "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={discount_attemps}
                        onChange={(val) =>
                          setdiscount_attemps(val.target.value)
                        }
                      />
                    </div>
                    <div className={role=='casher'?"w-full md:w-1/2 px-3":"hidden"}>
                      <label
                        className={
                        "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        نسبة التخفيظ
                      </label>
                      <input
                        className={
                          "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder=""
                        value={discount_range}
                        onChange={(val) =>
                          setdiscount_range(val.target.value)
                        }
                      />
                    </div>
                    <div className="w-full md:w-full px-3 mt-1">
                      <label
                        for="cate"
                        className={
                          "block mb-2 text-sm font-medium text-gray-900"
                        }
                      >
                        دور
                      </label>
                      <select
                        id="cate"
                        className={
                          "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
                        }
                        value={role}
                        onChange={(val) => {
                          if(val.target.value=='casher'){
                            setdiscount_attemps(0);
                            setdiscount_range(0);
                          }else{
                            setdiscount_attemps(100);
                            setdiscount_range(100);
                          }
                          setrole(val.target.value)
                        }}
                      >
                        <option value="admin">ئەدمین</option>
                        <option value="casher">کاشێر</option>
                        <option value="monitor">تقرير</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      props.setshow(false);
                    }}
                  >
                    اغلاق
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (username == "" || !username) {
                        seterror(true);
                        return false;
                      }
                      if (password == "" || !password) {
                        seterror1(true);
                        return false;
                      }
                        if (username == "" || !username) {
                            seterror(true);
                            return false;
                          }
                          if (password == "" || !password) {
                            seterror1(true);
                            return false;
                          }
                          createaction(username, password, role,discount_attemps,discount_range)(recaller)(setrecaller)(
                            initDispatch
                          )(clear);
                    }}
                  >
                    زيادة المستخدم
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Addusermodal