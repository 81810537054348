import { useaxios } from "../../../utils/useaxios";
export default (damage_id) => recaller => setrecaller => initDispatch => clear => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    initDispatch({ type: 'loadingTrue' });
    useaxios('damage/delete.php', { damage_id }).then((res) => {
        if (res.status == "ok") {
            initDispatch({ type: 'setbroke', payload: [] });
        }
        initDispatch({ type: 'loadingFalse' });
        // setloading(false);
    });
}