import { useaxios } from "../../../utils/useaxios";
export default (sell_invoice_note, user_id, items) => recaller => setrecaller => initDispatch => clear => printit => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getMinutes();
    // initDispatch({ type: 'loadingTrue' });
    useaxios('sell_invoice/create.php', { sell_invoice_note, user_id, sell_invoice_date: today, items }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            printit(res.invoiceno);
            setTimeout(() => {
                setrecaller(recaller + 1);
            }, 1000);
        } else {
            // clear();
        }
        // setloading(false);
    });
}