import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import getitemaction from "../../context/action/item/getitemaction";
import { MdDeleteForever } from "react-icons/md";
import createaction from "../../context/action/buy/createaction";

const Buy = () => {
  const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

  var today = new Date();
  today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  const [company_invoice_type, setcompany_invoice_type] = useState("cash");
  const [company_invoice_date, setcompany_invoice_date] = useState();
  const [company_invoice_number, setcompany_invoice_number] = useState();
  const [company_invoice_note, setcompany_invoice_note] = useState();
  const [company_id, setcompany_id] = useState("null");
  const [user_id, setuser_id] = useState();
  const [items, setitems] = useState([]);
  const [text, settext] = useState();

  const [error1, seterror1] = useState(false);
  const [error2, seterror2] = useState(false);

  return (
    <div>
      <div className="w-full h-20 flex justify-center items-center text-3xl">
      شراء
      </div>
      <div className="w-full flex">
        {/* <div className="w-full md:w-1/6 px-3 mt-1">
          <label
            for="cate"
            className={"block mb-2 text-sm font-medium text-gray-900"}
          >
            جۆری وەسڵ
          </label>
          <select
            id="cate"
            className={
              "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
            }
            value={company_invoice_type}
            onChange={(val) => setcompany_invoice_type(val.target.value)}
          >
            <option value="cash">کاش</option>
            <option value="loan">قەرز</option>
          </select>
        </div> */}
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              error1
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            تاريخ الدخول
          </label>
          <input
            className={
              error1
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={company_invoice_date}
            onChange={(val) => setcompany_invoice_date(val.target.value)}
            onFocus={() => seterror1(false)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              error2
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            رقم الوصل
          </label>
          <input
            className={
              error2
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="3.14"
            value={company_invoice_number}
            onChange={(val) => setcompany_invoice_number(val.target.value)}
            onFocus={() => seterror2(false)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            ملاحظة
          </label>
          <input
            className={
              "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="3.14"
            value={company_invoice_note}
            onChange={(val) => setcompany_invoice_note(val.target.value)}
          />
        </div>
        {/* <div className="w-full md:w-1/6 px-3 mt-1">
          <label
            for="cate"
            className={
              error3
                ? "block mb-2 text-sm font-medium text-red-900"
                : "block mb-2 text-sm font-medium text-gray-900"
            }
          >
            کۆمپانیای دابینکەر
          </label>
          <select
            id="cate"
            className={
              error3
                ? "bg-gray-50 border border-red-300 text-red-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
                : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
            }
            value={company_id}
            onChange={(val) => {
              setcompany_id(val.target.value);
              seterror3(false);
            }}
          >
            <option value="null">کۆمپانیا</option>
            {initState.company.map((lis) => (
              <option value={lis.company_id}>{lis.company_name}</option>
            ))}
          </select>
        </div> */}
        <div className="w-full md:w-1/4 px-3 mt-1">
          <button
            className={
              items.length > 0
                ? "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
                : "bg-blue-300 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded mt-8"
            }
            disabled={items.length > 0 ? false : true}
            onClick={() => {
              if (company_invoice_date == "" || !company_invoice_date) {
                seterror1(true);
                return false;
              }
              if (company_invoice_number == "" || !company_invoice_number) {
                seterror2(true);
                return false;
              }
              createaction(
                company_invoice_type,
                company_invoice_date,
                company_invoice_note,
                company_invoice_number,
                company_id,
                initState.token.id,
                items
              )(recaller)(setrecaller)(initDispatch)(clear);
            }}
          >
            زيادة الوصل
          </button>
        </div>
      </div>
      <div className="w-11/12 bg-gray-400 mr-10 mt-7 rounded-md p-2">
        <div className="w-full flex">
          <div className="w-full md:w-2/3 px-3 my-2">
            <label
              className={
                "block uppercase tracking-wide text-white text-xs font-bold mb-2"
              }
              for="grid-last-name"
            >
              اسم صنف / باڕکۆد
            </label>
            <input
              className={
                "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              }
              id="grid-last-name"
              type="text"
              placeholder="3.14"
              value={text}
              onChange={(val) => settext(val.target.value)}
              onKeyDown={(key) => {
                if (key.key == "Enter") {
                  getitemaction(text)(recaller)(setrecaller)(items)(setitems)(
                    settext
                  );
                }
              }}
            />
          </div>
          <div className="w-full md:w-1/3 px-3 mt-1">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
              onClick={() => {
                getitemaction(text)(recaller)(setrecaller)(items)(setitems)(
                  settext
                );
              }}
            >
             صنف
            </button>
          </div>
        </div>
      </div>
      {items.length > 0 ? (
        <div className="container mx-auto px-4 sm:px-8">
          <div className="py-8">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      اسم صنف
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        باڕکۆد
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      سعر الشراء
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      عدد
                      </th>
                      {/* <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        بەرواری بەسەرچوون
                      </th> */}
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      حذف
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((lis, index) => (
                      <tr key={index}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.item_name}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.item_barcode}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <input
                            className={
                              "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            }
                            id="grid-last-name"
                            type="number"
                            placeholder="3.14"
                            value={lis.company_invoice_item_price}
                            onChange={(val) => {
                              setitems(
                                items.map((lis, indexx) =>
                                  index == indexx
                                    ? {
                                        item_id: lis.item_id,
                                        item_name: lis.item_name,
                                        item_barcode: lis.item_barcode,
                                        company_invoice_item_qty:
                                          lis.company_invoice_item_qty,
                                        company_invoice_item_price:
                                          val.target.value,
                                        company_invoice_item_exp_date:
                                        today,
                                      }
                                    : lis
                                )
                              );
                            }}
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <input
                            className={
                              "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            }
                            id="grid-last-name"
                            type="number"
                            placeholder="3.14"
                            value={lis.company_invoice_item_qty}
                            onChange={(val) => {
                              setitems(
                                items.map((lis, indexx) =>
                                  index == indexx
                                    ? {
                                        item_id: lis.item_id,
                                        item_name: lis.item_name,
                                        item_barcode: lis.item_barcode,
                                        company_invoice_item_qty:
                                          val.target.value,
                                        company_invoice_item_price:
                                          lis.company_invoice_item_price,
                                        company_invoice_item_exp_date:
                                        today,
                                      }
                                    : lis
                                )
                              );
                            }}
                          />
                        </td>
                        {/* <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <input
                            className={
                              "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            }
                            id="grid-last-name"
                            type="date"
                            placeholder="3.14"
                            value={lis.company_invoice_item_exp_date}
                            onChange={(val) => {
                              setitems(
                                items.map((lis, indexx) =>
                                  index == indexx
                                    ? {
                                        item_id: lis.item_id,
                                        item_name: lis.item_name,
                                        item_barcode: lis.item_barcode,
                                        company_invoice_item_qty:
                                          lis.company_invoice_item_qty,
                                        company_invoice_item_price:
                                          lis.company_invoice_item_price,
                                        company_invoice_item_exp_date:
                                        today,
                                      }
                                    : lis
                                )
                              );
                            }}
                          />
                        </td> */}
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            <MdDeleteForever
                              size={28}
                              color="#e74c3c"
                              className="cursor-pointer"
                              onClick={() => {
                                setitems(
                                  items.filter(
                                    (list) => list.item_id != lis.item_id
                                  )
                                );
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Buy;
