import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/expense/createaction";

const Addexpensemodal = (props) => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
    const [expense_amount, setexpense_amount] = useState();
    const [expense_type_id, setexpense_type_id] = useState("null");
    const [expense_date, setexpense_date] = useState();
    const [expense_note, setexpense_note] = useState();

    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);
    const [error2, seterror2] = useState(false);
  return (
    <>
    {props.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto md:w-2/3 lg:w-1/2">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl w-full uppercase font-semibold">
                  زيادة المصاريف
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setshow(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      X
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-wrap w-full">
                    <div className="w-full md:w-full px-3 my-2">
                      <label
                        className={
                          error
                            ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                            : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        المصاريف
                      </label>
                      <input
                        className={
                          error
                            ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="number"
                        placeholder="3.14"
                        value={expense_amount}
                        onChange={(val) =>
                            setexpense_amount(val.target.value)
                        }
                        onFocus={() => seterror(false)}
                      />
                    </div>
                    <div className="w-full md:w-full px-3 mt-1">
                      <label
                        for="cate"
                        className={
                          error1
                            ? "block mb-2 text-sm font-medium text-red-500"
                            : "block mb-2 text-sm font-medium text-gray-900"
                        }
                      >
                        نوع المصاريف
                      </label>
                      <select
                        id="cate"
                        className={
                          error1
                            ? "bg-gray-50 border border-red-500 text-red-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
                            : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
                        }
                        value={expense_type_id}
                        onChange={(val) => {
                          seterror1(false);
                          setexpense_type_id(val.target.value);
                        }}
                      >
                        <option value="null">حدد نوع المصاريف</option>
                        {initState.active_expense_type.map((lis) => (
                          <option value={lis.expense_type_id}>
                            {lis.expense_type_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full md:w-full px-3 my-2">
                      <label
                        className={
                          error2
                          ? "block mb-2 text-sm font-medium text-red-500"
                          :"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        تاريخ المصاريف
                      </label>
                      <input
                        className={
                          error2
                          ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          :"appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="date"
                        placeholder="بەرواری خەرجی"
                        value={expense_date}
                        onChange={(val) =>
                            setexpense_date(val.target.value)
                        }
                        onFocus={()=>{seterror2(false)}}
                      />
                    </div>
                    <div className="w-full md:w-full px-3 my-2">
                      <label
                        className={
                          "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        ملاحظة
                      </label>
                      <input
                        className={
                          "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder="Note"
                        value={expense_note}
                        onChange={(val) =>
                          setexpense_note(val.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (
                        expense_type_id == "null" ||
                        !expense_type_id
                      ) {
                        seterror1(true);
                        return false;
                      }
                      if (!expense_amount) {
                        seterror(true);
                        return false;
                      }
                      if (!expense_date) {
                        seterror2(true);
                        return false;
                      }
                        createaction(
                            expense_amount,
                            expense_type_id,
                            expense_date,
                            expense_note,
                            initState.token.id
                        )(recaller)(setrecaller)(initDispatch)(clear);
                    }}
                  >
                    زيادة المصاريف
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}

export default Addexpensemodal