import { useEffect, useReducer, createContext, useState } from "react";
import Initreducer from "./reducer/initreducer";
import initstate from "./initialstate/initstate";
import initaction from "./action/init/initaction";

export const GlobalContext = createContext({});
const GlobalProvider = ({ children }) => {
    const [recaller, setrecaller] = useState(0);

    const [initState, initDispatch] = useReducer(Initreducer, initstate);

    const clear = () => {
        initDispatch({ type: 'loadingTrue' });
        localStorage.setItem('sskmtoken', null);
        initDispatch({ type: 'settoken', payload: null });
        initDispatch({ type: 'loadingFalse' });
    }

    useEffect(() => {
        const tokenString = localStorage.getItem('sskmtoken');
        if (tokenString != null && tokenString != 'null') {
            var tok = JSON.parse(tokenString);
            initDispatch({ type: 'settoken', payload: tok });
            initaction(tok.id, tok.role)(initDispatch)(clear)
        } else {
            initDispatch({ type: 'loadingFalse' });
        }
        // initaction()(initDispatch);
    }, [recaller]);

    return <GlobalContext.Provider value={{ initState, initDispatch, recaller, setrecaller, clear }}>{children}</GlobalContext.Provider>
}

export default GlobalProvider