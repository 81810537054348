import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/user/createaction";
import Userlist from "./userlist";
import Addusermodal from "./addusermodal";

const User = () => {
  const { recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

  const [username, setusername] = useState();
  const [password, setpassword] = useState();
  const [discount_attemps, setdiscount_attemps] = useState(100);
  const [discount_range, setdiscount_range] = useState(100);
  const [role, setrole] = useState("admin");
  const [show,setshow]=useState(false);

  const [error, seterror] = useState(false);
  const [error1, seterror1] = useState(false);
  return (
    <div>
      <Addusermodal show={show} setshow={setshow}  />
      <div className="w-full h-20 flex justify-center items-center text-3xl">
      المستخدمين
      </div>
      <div className="hidden w-full lg:flex">
        <div className={role=='casher'?"w-full md:w-1/6 px-3 my-2":"w-full md:w-1/4 px-3 my-2"}>
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            اسم
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="Ex. Jone Doe"
            value={username}
            onChange={(val) => setusername(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className={role=='casher'?"w-full md:w-1/6 px-3 my-2":"w-full md:w-1/4 px-3 my-2"}>
          <label
            className={
              error1
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            الكلمة السرية
          </label>
          <input
            className={
              error1
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="Ex. Jone Doe"
            value={password}
            onChange={(val) => setpassword(val.target.value)}
            onFocus={() => seterror1(false)}
          />
        </div>
        <div className={role=='casher'?"w-full md:w-1/6 px-3 my-2":"hidden w-full md:w-1/4 px-3 my-2"}>
          <label
            className={
              "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            تخفيظات الشهرية
          </label>
          <input
            className={
              "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="Ex. Jone Doe"
            value={discount_attemps}
            onChange={(val) => setdiscount_attemps(val.target.value)}
          />
        </div>
        <div className={role=='casher'?"w-full md:w-1/6 px-3 my-2":"hidden w-full md:w-1/4 px-3 my-2"}>
          <label
            className={
             "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            نسبة التخفيظ
          </label>
          <input
            className={
               "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="Ex. Jone Doe"
            value={discount_range}
            onChange={(val) => setdiscount_range(val.target.value)}
          />
        </div>
        <div className={role=='casher'?"w-full md:w-1/6 px-3 my-2":"w-full md:w-1/4 px-3 my-2"}>
          <label
            for="cate"
            className={"block mb-2 text-sm font-medium text-gray-900"}
          >
            دور
          </label>
          <select
            id="cate"
            className={
              "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
            }
            value={role}
            onChange={(val) =>{
              if(val.target.value=='casher'){
                setdiscount_attemps(0);
                setdiscount_range(0);
              }else {
                setdiscount_attemps(100);
                setdiscount_range(100);
              } 
              setrole(val.target.value)
            }}
          >
            <option value="admin">ئەدمین</option>
            <option value="casher">کاشێر</option>
            <option value="monitor">تقرير</option>
          </select>
        </div>
        <div className="w-full md:w-1/4 px-3 mt-1">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={() => {
              if (username == "" || !username) {
                seterror(true);
                return false;
              }
              if (password == "" || !password) {
                seterror1(true);
                return false;
              }
              createaction(username, password, role,discount_attemps,discount_range)(recaller)(setrecaller)(
                initDispatch
              )(clear);
              // checkaction(username,password)(initDispatch)
            }}
          >
            زيادة المستخدم
          </button>
        </div>
      </div>
      <div className="flex items-center justify-center lg:hidden w-full h-14">
      <button
            className="w-11/12 h-12 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              setshow(true);
            }}
          >
            زيادة المستخدم
          </button>
      </div>
      <div>
        <Userlist />
      </div>
    </div>
  );
};

export default User;
