import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import getinvoiceaction from "../../context/action/buy/getinvoiceaction";
import Buyinvoicedetailmodal from "./buyinvoicedetailmodal";
import numberbeautify from "../../utils/numberbeautify";

const Buyinvoice = () => {
  const { initState, recaller, setrecaller, clear, initDispatch } =
    useContext(GlobalContext);

  const [company_id, setcompany_id] = useState("");
  const [from, setfrom] = useState("");
  const [to, setto] = useState("");
  const [barcode,setbarcode]=useState("");

  const [show, setshow] = useState(false);
  const [company_invoice_id, setcompany_invoice_id] = useState();
  const [items, setitems] = useState([]);
  return (
    <div>
      <Buyinvoicedetailmodal
        show={show}
        setshow={setshow}
        company_invoice_id={company_invoice_id}
        items={items}
        setitems={setitems}
      />
      <div className="w-full h-20 flex justify-center items-center text-3xl">
      وصل المشتريات
      </div>
      <div className="w-full flex">
        {/* <div className="w-full md:w-1/5 px-3 mt-1">
          <label
            for="cate"
            className={"block mb-2 text-sm font-medium text-gray-900"}
          >
            کۆمپانیا
          </label>
          <select
            id="cate"
            className={
              "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
            }
            value={company_id}
            onChange={(val) => {
              setcompany_id(val.target.value);
            }}
          >
            <option value="">کۆمپانیا هەڵبژێرە</option>
            {initState.company.map((lis) => (
              <option value={lis.company_id}>{lis.company_name}</option>
            ))}
          </select>
        </div> */}
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            من تاريخ 
          </label>
          <input
            className={
              "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={from}
            onChange={(val) => setfrom(val.target.value)}
          />
        </div>
        <div className="w-full md:w-1/5 px-3 my-2">
          <label
            className={
              "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-namee"
          >
            الى تاريخ
          </label>
          <input
            className={
              "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-namee"
            type="date"
            placeholder="3.14"
            value={to}
            onChange={(val) => setto(val.target.value)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-namee"
          >
            باڕکۆد
          </label>
          <input
            className={
              "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-namee"
            type="text"
            placeholder="3.14"
            value={barcode}
            onChange={(val) => setbarcode(val.target.value)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 mt-1">
          <button
            className={
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            }
            onClick={() => {
              getinvoiceaction(from, to,barcode ,company_id)(recaller)(setrecaller)(
                initDispatch
              )(clear);
            }}
          >
            بحث
          </button>
        </div>
      </div>
      {initState.company_invoice_item.length > 0 ? (
        <div className="container mx-auto px-4 sm:px-8">
          <div className="py-8">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      {/* <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        کۆمپانیا
                      </th> */}
                      {/* <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        جۆری وەسڵ
                      </th> */}
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      رقم الوصل
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      تاريخ
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      مجموع سعر الكل
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      ملاحظة
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {initState.company_invoice_item.map((lis, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setcompany_invoice_id(lis.company_invoice_id);
                          setitems(lis.items);
                          setshow(true);
                        }}
                        className="cursor-pointer"
                      >
                        {/* <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.company_name}
                          </p>
                        </td> */}
                        {/* <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.company_invoice_type=="loan"?"قەرز":"کاش"}
                          </p>
                        </td> */}
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.company_invoice_number}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.company_invoice_date}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {numberbeautify(lis.items.reduce((a, v) => a = Number(a) + (Number(v.company_invoice_item_price)*Number(v.company_invoice_item_qty)), 0))}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.company_invoice_note}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Buyinvoice;
