import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/category/createaction";
import Categorylist from "./categorylist";

const Category = () => {
  const { recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

  const [category, setcategory] = useState();
  const [error, seterror] = useState(false);
  return (
    <div>
      <div className="w-full h-20 flex justify-center items-center text-3xl">
      نوع
      </div>
      <div className="w-full flex">
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            نوع
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="Ex. Jone Doe"
            value={category}
            onChange={(val) => setcategory(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 mt-1">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={() => {
              if (category == "" || !category) {
                seterror(true);
                return false;
              }
              createaction(category)(recaller)(setrecaller)(initDispatch)(
                clear
              );
            }}
          >
            زيادة نوع
          </button>
        </div>
      </div>
      <Categorylist />
    </div>
  );
};

export default Category;
