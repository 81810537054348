import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import numberbeautify from "../../utils/numberbeautify";

const Jardtable = props => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
    var today = new Date();
    today.setMonth(today.getMonth()+7);
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // today.setMonth(today.getMonth()-7)
  return (
    <div>
        <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    اسم صنف
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    باڕکۆد
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    سعر
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    المبلغ المتبقي
                    </th>
                    {/* <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    بەرواری بەسەرچوون
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                    {initState.stok.map((lis)=>(
                    <tr key={lis.item_id} className={Number(lis.item_min_notification) < Number(lis.item_qty)?"bg-white":"bg-yellow-400"}>
                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.item_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200  text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.item_barcode}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {numberbeautify(lis.item_price)}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200  text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.item_qty}
                        </p>
                      </td>
                      {/* <td className="px-5 py-5 border-b border-gray-200  text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.item_exp_date}
                        </p>
                      </td> */}
                    </tr>
                    ))}
                </tbody>
                </table>
                </div>
                </div>
                </div>
                </div>
    </div>
  )
}

export default Jardtable