import { useaxios } from "../../../utils/useaxios";
export default (from, to, casher_id) => recaller => setrecaller => initDispatch => clear => settype => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // initDispatch({ type: 'loadingTrue' });
    useaxios('report/sell.php', { from, to, user_id: casher_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            initDispatch({ type: 'setsell_report', payload: { total_sell_qty: res.total_qty, total_sell_price: res.total_price, total_return_qty: res.total_return_qty, total_return_price: res.total_return_price } });
            settype('sell');
            // setrecaller(recaller + 1);
        } else {
            // clear();
        }
        // setloading(false);
    });
}