const Initreducer = (state, { type, payload }) => {
    switch (type) {
        case 'settoken':
            return { ...state, token: payload, islogin: true };
        case 'setinit':
            return { ...state, loading: false, user: payload.user, role: payload.role, expense_type: payload.expense_type, active_expense_type: payload.active_expense_type, expense: payload.expense, category: payload.category, company: payload.company, item: payload.item, today_invoice: payload.today_invoice, return_item_company: payload.return_item_company, removeditem: payload.removeditem, discount_attemp: payload.discount_attemp, discount_range: payload.discount_range };
        case 'setcompanyinvoiceitem':
            return { ...state, company_invoice_item: payload };
        case 'setsellinvoice':
            return { ...state, today_invoice: payload };
        case 'setexpense_report':
            return { ...state, expense_report: payload };
        case 'setcompany_report':
            return { ...state, company_report: payload };
        case 'setbroke':
            return { ...state, broke: payload };
        case 'setbrokereport':
            return { ...state, total_broke_qty: payload.total_broke_qty, total_broke_price: payload.total_broke_price };
        case 'filtercompanyinvoiceitem':
            return { ...state, company_invoice_item: state.company_invoice_item.filter((lis) => lis.company_invoice_id != payload) };
        case 'setsell_report':
            return { ...state, total_sell_qty: payload.total_sell_qty, total_sell_price: payload.total_sell_price, total_return_qty: payload.total_return_qty, total_return_price: payload.total_return_price };
        case 'setreturnbybarcode':
            return { ...state, returnbybarcode: payload };
        case 'setitemsellreport':
            return { ...state, item_sell_report_qty: payload.sell_qty, item_sell_report_price: payload.sell_price };
        case 'setreturnitemcompany':
            return { ...state, return_item_company: payload };
        case 'setsummaryreport':
            return { ...state, summary_report_sell_array: payload.summary_report_sell_array, summary_report_expense_amount: payload.summary_report_expense_amount, summary_report_damage_qty: payload.summary_report_damage_qty, summary_report_damage_amount: payload.summary_report_damage_amount, summary_report_return_item_company_qty: payload.summary_report_return_item_company_qty, summary_report_return_item_company_amount: payload.summary_report_return_item_company_amount };
        case 'loadingFalse':
            return { ...state, loading: false };
        case 'loadingTrue':
            return { ...state, loading: true };
        case 'authcheckFalse':
            return { ...state, authcheck: false };
        case 'authcheckTrue':
            return { ...state, authcheck: true };
        case 'setstok':
            return { ...state, stok: payload };
        default:
            return state;
    }
}

export default Initreducer;