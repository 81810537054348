import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import deleteaction from "../../context/action/category/deleteaction";
import updateaction from "../../context/action/category/updateaction";
const Editcategorymodal = (props) => {
  const { initState, recaller, setrecaller, clear, initDispatch } =
    useContext(GlobalContext);

  const [error, seterror] = useState(false);
  return (
    <div>
      <>
        {props.show ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative  my-6 mx-auto md:w-2/3 lg:w-1/2">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl w-full uppercase font-semibold">
                    تغير
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => props.setshow(false)}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        X
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="flex flex-wrap w-full">
                      <div className="w-full md:w-full px-3">
                        <label
                          className={
                            error
                              ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                              : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          }
                          for="grid-last-name"
                        >
                          نوع
                        </label>
                        <input
                          className={
                            error
                              ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          }
                          id="grid-last-name"
                          type="text"
                          placeholder="Pop Cake"
                          value={props.category_name}
                          onChange={(val) =>
                            props.setcategory_name(val.target.value)
                          }
                          onFocus={() => seterror(false)}
                        />
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        if (
                          window.confirm("إشعار الحذف. هل أنت متأكد؟") ==
                          true
                        ) {
                          deleteaction(props.category_id)(recaller)(
                            setrecaller
                          )(initDispatch)(clear);
                        }
                      }}
                    >
                      حذف
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        if (props.category_name == "" || !props.category_name) {
                          seterror(true);
                          return false;
                        }
                        if (
                          window.confirm(
                            "إشعار بإجراء التغييرات. هل أنت متأكد؟"
                          ) == true
                        ) {
                          updateaction(
                            props.category_name,
                            props.category_id
                          )(recaller)(setrecaller)(initDispatch)(clear);
                        }
                      }}
                    >
                      تغير
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    </div>
  );
};

export default Editcategorymodal;
