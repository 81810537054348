import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import { MdDeleteForever } from "react-icons/md";
import numberbeautify from "../../utils/numberbeautify";
import '../../component/css/onprintcss.css';
import getitemaction from "../../context/action/returnbybarcode/getitemaction";
import Sellinvoicedetailmodal from "../sell/sellinvoicedetailmodal";
import Sellitemprint from "../sell/sellitemprint";
import '../../component/css/onprintcss.css';

const Returnbybarcode = () => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
    const [barcode,setbarcode]=useState();
    const [show,setshow]=useState(false);
    const [invoice_detail, setinvoice_detail] = useState();
    const [invoiceno, setinvoiceno] = useState(0);

    const printit=(invoiceno)=>{
        setinvoiceno(invoiceno);
        setTimeout(() => {
          window.print();
        }, 500);
      }
  return (
    <div>
        <Sellitemprint username={initState.token.username} items={invoice_detail?invoice_detail.items:[]} invoiceno={invoiceno} />
        <div className="print_hide">
        <Sellinvoicedetailmodal
        show={show}
        setshow={setshow}
        invoice_detail={invoice_detail}
        setinvoice_detail={setinvoice_detail}
        printit={printit}
      />
        <div className="w-full h-20 flex justify-center items-center text-3xl">
        تبديل صنف
      </div>
      <div className="w-full flex">
        <div className="w-10/12 bg-gray-400 mr-10 mt-7 rounded-md p-2 flex">
          <div className="w-full md:w-3/3 px-3 my-2">
            <label
              className={
                "block uppercase tracking-wide text-white text-xs font-bold mb-2"
              }
              for="grid-last-name"
            >
              رقم الوصل
            </label>
            <input
              className={
                "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              }
              id="grid-last-name"
              type="text"
              placeholder="3.14"
              value={barcode}
              onChange={(val) => setbarcode(val.target.value)}
              autoFocus
              onKeyDown={(key) => {
                if (key.key == "Enter") {
                  getitemaction(barcode)(recaller)(setrecaller)(initDispatch)(
                    setbarcode
                  );
                }
              }}
            />
          </div>
          </div>
          </div>
          {initState.returnbybarcode.length>0?
          <div className="container mx-auto px-4 sm:px-8">
          <div className="">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      رقم الوصل
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      عدد
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      مجموع سعر الوصل
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      مجموع السعر الكلي
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      ملاحظة
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {initState.returnbybarcode.map((lis, index) => (
                      <tr
                        key={index}
                        className="cursor-pointer"
                        onClick={() => {
                            setinvoice_detail(lis);
                            setshow(true);
                          }}
                      >
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.sell_invoice_id}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {numberbeautify(
                              lis.items.reduce(
                                (a, v) => (a = Number(a) + Number(v.item_qty)),
                                0
                              )
                            )}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {numberbeautify(lis.items.reduce(
                                (a, v) => (a = Number(a) + (Number(v.item_qty)*Number(v.item_price))),
                                0
                              ))}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                          {numberbeautify(lis.items.reduce(
                                (a, v) => (a = Number(a) + (Number(v.item_qty)*Number(v.item_sell_price))),
                                0
                              ))}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.sell_invoice_note}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
          :null}
          </div>
    </div>
  )
}

export default Returnbybarcode