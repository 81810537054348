import { useaxios } from "../../../utils/useaxios";
export default (barcode, item_qty, user_id, damage_id) => recaller => setrecaller => initDispatch => clear => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    initDispatch({ type: 'loadingTrue' });
    useaxios('damage/update.php', { barcode, item_qty, damage_date: today, user_id, damage_id }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            initDispatch({ type: 'setbroke', payload: [] });
            setrecaller(recaller + 1);
        } else if (res.status == "out") {
            window.alert('بڕی دیاریکراو لەم کاڵایە نییە');
        } else if (res.status == "out") {
            window.alert('داخڵکردن سەرکەوتو نەبوو تکایە دڵنیابە لە داتای داخڵکراو');
        }
        initDispatch({ type: 'loadingFalse' });
        // setloading(false);
    });
}