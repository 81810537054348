import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import jardaction from '../../context/action/report/jardaction.js'
import Jardtable from "./jardtable";
import expense from "../../context/action/report/expense";
import Expensetable from "./expensetable";
import sellreportaction from "../../context/action/report/sellreportaction";
import Selltable from "./selltable";
import companyaction from "../../context/action/report/companyaction";
import Companytable from "./companytable";
import brokeaction from "../../context/action/report/brokeaction";
import Broketable from "./broketable";
import summaryaction from "../../context/action/report/summaryaction";
import Summarytable from "./summarytable";
import Itemselltable from "./itemselltable";
import itemsellaction from "../../context/action/report/itemsellaction";
const Report = () => {
  const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
  const [from, setfrom] = useState();
  const [to, setto] = useState();
  const [company_id, setcompany_id] = useState();
  const [casher_id, setcasher_id] = useState();
  const [category_id, setcategory_id] = useState();
  const [barcode, setbarcode] = useState();

  const [type, settype] = useState();
  const [error, seterror] = useState(false);
  const [error1, seterror1] = useState(false);
  const [error2, seterror2] = useState(false);
  const [error3, seterror3] = useState(false);
  return (
    <div>
      <div className="w-full h-20 flex justify-center items-center text-3xl">
      تقرير
      </div>
      <div className="w-full hidden md:flex">
        <div className="w-full md:w-1/5 px-3 mt-1">
          <label
            for="catee"
            className={"block mb-2 text-sm font-medium text-gray-900"}
          >
            کاشێر
          </label>
          <select
            id="catee"
            className={
              "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
            }
            value={casher_id}
            onChange={(val) => {
              setcasher_id(val.target.value);
            }}
          >
            <option value="">کاشێر</option>
            {initState.user.map((lis) => (
              <option value={lis.user_id}>{lis.user_name}</option>
            ))}
          </select>
        </div>
        {/* <div className="w-full md:w-1/6 px-3 mt-1">
          <label
            for="cate"
            className={error2?"block mb-2 text-sm font-medium text-red-900":"block mb-2 text-sm font-medium text-gray-900"}
          >
            کۆمپانیای دابینکەر
          </label>
          <select
            id="cate"
            className={error2?
              "bg-gray-50 border border-red-300 text-red-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4":
              "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
            }
            value={company_id}
            onChange={(val) => {
              setcompany_id(val.target.value);
              seterror2(false)
            }}
          >
            <option value="">کۆمپانیا</option>
            {initState.company.map((lis) => (
              <option value={lis.company_id}>{lis.company_name}</option>
            ))}
          </select>
        </div> */}
        <div className="w-full md:w-1/5 px-3 mt-1">
          <label
            for="cate"
            className={error2?"block mb-2 text-sm font-medium text-red-900":"block mb-2 text-sm font-medium text-gray-900"}
          >
            نوع
          </label>
          <select
            id="cate"
            className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
            }
            value={category_id}
            onChange={(val) => {
              setcategory_id(val.target.value)
            }}
          >
            <option value="">نوع</option>
            {initState.category.map((lis) => (
              <option value={lis.category_id}>{lis.category_name}</option>
            ))}
          </select>
        </div>
        <div className="w-full md:w-1/5 px-3 my-2">
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-700 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            من تاريخ{" "}
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-red-700 border border-red-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={from}
            onChange={(val) => setfrom(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className="w-full md:w-1/5 px-3 my-2">
          <label
            className={
              error1
                ? "block uppercase tracking-wide text-red-700 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            الى تاريخ
          </label>
          <input
            className={
              error1
                ? "appearance-none block w-full bg-gray-50 text-red-700 border border-red-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={to}
            onChange={(val) => setto(val.target.value)}
            onFocus={() => seterror1(false)}
          />
        </div>
        <div className="w-full md:w-1/5 px-3 my-2">
          <label
            className={
              error3
                ? "block uppercase tracking-wide text-red-700 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            باڕکۆد{" "}
          </label>
          <input
            className={
              error3
                ? "appearance-none block w-full bg-gray-50 text-red-700 border border-red-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="3.14"
            value={barcode}
            onChange={(val) => setbarcode(val.target.value)}
            onFocus={() => seterror3(false)}
          />
        </div>
      </div>
      <div className="w-full flex md:hidden">
          <div className="w-full md:w-1/2 px-1 mt-1">
            <label
              for="catee"
              className={"block mb-2 text-sm font-medium text-gray-900"}
            >
              کاشێر
            </label>
            <select
              id="catee"
              className={
                "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
              }
              value={casher_id}
              onChange={(val) => {
                setcasher_id(val.target.value);
              }}
            >
              <option value="">کاشێر</option>
              {initState.user.map((lis) => (
                <option value={lis.user_id}>{lis.user_name}</option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-1/2 px-1 mt-1">
            <label
              for="cate"
              className={error2?"block mb-2 text-sm font-medium text-red-900":"block mb-2 text-sm font-medium text-gray-900"}
            >
              نوع
            </label>
            <select
              id="cate"
              className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
              }
              value={category_id}
              onChange={(val) => {
                setcategory_id(val.target.value)
              }}
            >
              <option value="">نوع</option>
              {initState.category.map((lis) => (
                <option value={lis.category_id}>{lis.category_name}</option>
              ))}
            </select>
        </div>
      </div>
      <div className="w-full flex md:hidden">
      <div className="w-full md:w-1/2 px-1 my-2">
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-700 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            من تاريخ{" "}
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-red-700 border border-red-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={from}
            onChange={(val) => setfrom(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className="w-full md:w-1/2 px-1 my-2">
          <label
            className={
              error1
                ? "block uppercase tracking-wide text-red-700 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            الى تاريخ{" "}
          </label>
          <input
            className={
              error1
                ? "appearance-none block w-full bg-gray-50 text-red-700 border border-red-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={to}
            onChange={(val) => setto(val.target.value)}
            onFocus={() => seterror1(false)}
          />
        </div>
      </div>
      <div className="w-full flex md:hidden">
        <div className="w-full px-1 my-2">
          <label
            className={
              error3
                ? "block uppercase tracking-wide text-red-700 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            باڕکۆد{" "}
          </label>
          <input
            className={
              error3
                ? "appearance-none block w-full bg-gray-50 text-red-700 border border-red-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="3.14"
            value={barcode}
            onChange={(val) => setbarcode(val.target.value)}
            onFocus={() => seterror3(false)}
          />
        </div>
      </div>
      <div className="justify-center hidden md:flex">
        <div className="cursor-pointer w-1/4 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]" onClick={()=>{
          jardaction(category_id)(recaller)(setrecaller)(initDispatch)(clear)(settype);
        }}>
          <div className="p-6">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
            تجريد الخزينة
            </h5>
          </div>
          <div className="border-t-2 border-neutral-100 px-6 py-3">
            نوع
          </div>
        </div>
        <div
          className="cursor-pointer w-1/4 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
          onClick={() => {
            if (!from || from == "") {
              seterror(true);
              return false;
            }
            if (!to || to == "") {
              seterror1(true);
              return false;
            }
            expense(from,to)(recaller)(setrecaller)(initDispatch)(clear)(settype);
          }}
        >
          <div className="p-6">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
            مصاريف  
            </h5>
          </div>
          <div className="border-t-2 border-neutral-100 px-6 py-3">
          من تاريخ , الى تاريخ{" "}
          </div>
        </div>
        {/* <div className="cursor-pointer w-1/3 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]" onClick={()=>{
          if(!company_id||company_id==''){
            seterror2(true);
            return false;
          }
          companyaction(company_id)(recaller)(setrecaller)(initDispatch)(clear)(settype);
        }}>
          <div className="p-6">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
              کۆمپانیا
            </h5>
          </div>
          <div className="border-t-2 border-neutral-100 px-6 py-3">
            {" "}
            کۆمپانیای دابینکەر
          </div>
        </div> */}
        <div className="cursor-pointer w-1/4 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]" onClick={()=>{
          if (!from || from == "") {
            seterror(true);
            return false;
          }
          if (!to || to == "") {
            seterror1(true);
            return false;
          }
          sellreportaction(from,to,casher_id)(recaller)(setrecaller)(initDispatch)(clear)(settype);
        }}>
          <div className="p-6">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
            مبيعات
            </h5>
          </div>
          <div className="border-t-2 border-neutral-100 px-6 py-3">
            {" "}
            کاشێر , من تاريخ , الى تاريخ
          </div>
        </div>
        {/* <div className="cursor-pointer w-1/4 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]" onClick={()=>{
          if (!from || from == "") {
            seterror(true);
            return false;
          }
          if (!to || to == "") {
            seterror1(true);
            return false;
          }
          brokeaction(from,to)(recaller)(setrecaller)(initDispatch)(clear)(settype);
        }}>
          <div className="p-6">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
            خەسار
            </h5>
          </div>
          <div className="border-t-2 border-neutral-100 px-6 py-3">
            {" "}
            لە بەرواری , بۆ بەرواری
          </div>
        </div> */}
        <div className="cursor-pointer w-1/4 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]" onClick={()=>{
          if (!from || from == "") {
            seterror(true);
            return false;
          }
          if (!to || to == "") {
            seterror1(true);
            return false;
          }
          if (!barcode || barcode == "") {
            seterror3(true);
            return false;
          }
          itemsellaction(from,to,barcode)(recaller)(setrecaller)(initDispatch)(clear)(settype)
        }}>
          <div className="p-6">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
            مبيعات صنف
            </h5>
          </div>
          <div className="border-t-2 border-neutral-100 px-6 py-3">
            {" "}
            من تاريخ , الى تاريخ , باڕکۆد
          </div>
        </div>
        {/* <div className="cursor-pointer w-1/4 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]" onClick={()=>{
          if (!from || from == "") {
            seterror(true);
            return false;
          }
          if (!to || to == "") {
            seterror1(true);
            return false;
          }
          summaryaction(from,to)(recaller)(setrecaller)(initDispatch)(clear)(settype)
        }}>
          <div className="p-6">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
              پوختە
            </h5>
          </div>
          <div className="border-t-2 border-neutral-100 px-6 py-3">
            {" "}
            لە بەرواری , بۆ بەرواری
          </div>
        </div> */}
      </div>
      <div className="flex md:hidden justify-center">
        <div className="cursor-pointer w-1/2 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]" onClick={()=>{
            jardaction(category_id)(recaller)(setrecaller)(initDispatch)(clear)(settype);
          }}>
          <div className="p-6">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
            تجريد الخزينة
            </h5>
          </div>
          <div className="border-t-2 border-neutral-100 px-6 py-3">
          نوع
          </div>
        </div>
        <div
          className="cursor-pointer w-1/2 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
          onClick={() => {
            if (!from || from == "") {
              seterror(true);
              return false;
            }
            if (!to || to == "") {
              seterror1(true);
              return false;
            }
            expense(from,to)(recaller)(setrecaller)(initDispatch)(clear)(settype);
          }}
        >
          <div className="p-6">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
            مصاريف
            </h5>
          </div>
          <div className="border-t-2 border-neutral-100 px-6 py-3">
          من تاريخ , الى تاريخ{" "}
          </div>
        </div>
      </div>
      <div className="flex md:hidden justify-center">
          <div className="cursor-pointer w-1/2 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]" onClick={()=>{
              if (!from || from == "") {
                seterror(true);
                return false;
              }
              if (!to || to == "") {
                seterror1(true);
                return false;
              }
              sellreportaction(from,to,casher_id)(recaller)(setrecaller)(initDispatch)(clear)(settype);
            }}>
            <div className="p-6">
              <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
              مبيعات
              </h5>
            </div>
            <div className="border-t-2 border-neutral-100 px-6 py-3">
              {" "}
              کاشێر , من تاريخ , الى تاريخ
            </div>
          </div>
          <div className="cursor-pointer w-1/2 mx-3 mt-10 block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]" onClick={()=>{
            if (!from || from == "") {
              seterror(true);
              return false;
            }
            if (!to || to == "") {
              seterror1(true);
              return false;
            }
            if (!barcode || barcode == "") {
              seterror3(true);
              return false;
            }
            itemsellaction(from,to,barcode)(recaller)(setrecaller)(initDispatch)(clear)(settype)
          }}>
            <div className="p-6">
              <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800">
              مبيعات صنف
              </h5>
            </div>
            <div className="border-t-2 border-neutral-100 px-6 py-3">
              {" "}
              من تاريخ , الى تاريخ , باڕکۆد
            </div>
          </div>
      </div>
      <div>
        {type=='jard'?<Jardtable />:type=='expense'?<Expensetable />:type=='sell'?<Selltable />:type=='company'?<Companytable />:type=='broken'?<Broketable />:type=='summary'?<Summarytable />:type=='itemsellreport'?<Itemselltable />:null}
      </div>
    </div>
  );
};

export default Report;
