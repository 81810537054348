import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/broke/createaction";
import getaction from "../../context/action/broke/getaction";
import Brokelist from "./brokelist";

const Broke = () => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
    const [barcode,setbarcode]=useState();
    const [qty,setqty]=useState();
    const [from,setfrom]=useState();
    const [to,setto]=useState();

    const [error,seterror]=useState(false);
    const [error1,seterror1]=useState(false);
    const [error2,seterror2]=useState(false);
    const [error3,seterror3]=useState(false);
    
  return (
    <div>
        <div className="w-full h-20 flex justify-center items-center text-3xl">
        خەسار
      </div>
      <div className="w-full flex">
      <div className="w-full md:w-2/5 px-3 my-2">
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            باڕکۆد
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="3.14"
            value={barcode}
            onChange={(val) => setbarcode(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className="w-full md:w-2/5 px-3 my-2">
          <label
            className={
              error1
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            بڕی خەسار
          </label>
          <input
            className={
              error1
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="number"
            placeholder="3.14"
            value={qty}
            onChange={(val) => setqty(val.target.value)}
            onFocus={() => seterror1(false)}
          />
        </div>
        <div className="w-full md:w-1/5 px-3 mt-1">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={() => {
              if (barcode == "" || !barcode) {
                seterror(true);
                return false;
              }
              if (qty < 0 || !qty) {
                seterror1(true);
                return false;
              }
              createaction(barcode,qty,initState.token.id)(recaller)(setrecaller)(initDispatch)(clear);
            //   createaction(
            //     expense_amount,
            //     expense_type_id,
            //     expense_note,
            //     initState.token.id
            //   )(recaller)(setrecaller)(initDispatch)(clear);
            }}
          >
            زیادکردن
          </button>
        </div>
    </div>
    <div className="w-full h-20 flex justify-right items-center text-3xl border-b-2 px-5">
    لیستی خەسار
      </div>
      <div className="w-full flex">
      <div className="w-full md:w-2/5 px-3 my-2">
          <label
            className={
              error2
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            لە بەرواری
          </label>
          <input
            className={
              error2
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={from}
            onChange={(val) => setfrom(val.target.value)}
            onFocus={() => seterror2(false)}
          />
        </div>
        <div className="w-full md:w-2/5 px-3 my-2">
          <label
            className={
              error3
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            بۆ بەرواری
          </label>
          <input
            className={
              error3
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={to}
            onChange={(val) => setto(val.target.value)}
            onFocus={() => seterror3(false)}
          />
        </div>
        <div className="w-full md:w-1/5 px-3 mt-1">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={() => {
              if (from == "" || !from) {
                seterror2(true);
                return false;
              }
              if (to == "" || !to) {
                seterror3(true);
                return false;
              }
              getaction(from,to)(recaller)(setrecaller)(initDispatch)(clear);
            }}
          >
            گەڕان
          </button>
        </div>
      </div>
      <div>
        {initState.broke.length>0?<Brokelist />:null}
      </div>
    </div>
  )
}

export default Broke