import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/return_item_company/createaction";
import Return_item_companylist from "./return_item_companylist";

const Return_item_company = () => {
    const { initState,recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
    const [barcode,setbarcode]=useState();
    const [qty,setqty]=useState();
    const [price,setprice]=useState();
    const [company_id,setcompany_id]=useState();
    const [note,setnote]=useState();

    const [error,seterror]=useState(false);
    const [error1,seterror1]=useState(false);
    const [error2,seterror2]=useState(false);
    const [error3,seterror3]=useState(false);
  return (
    <div>
        <div className="w-full h-20 flex justify-center items-center text-3xl">
        گەڕانەوەی کاڵا بۆ کۆمپانیا
      </div>
      <div className="w-full flex">
        <div className="w-full md:w-1/6 px-3 my-2">
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            باڕکۆد
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="1234"
            value={barcode}
            onChange={(val) => setbarcode(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className="w-full md:w-1/6 px-3 my-2">
          <label
            className={
              error1
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            بڕی گەڕاوە
          </label>
          <input
            className={
              error1
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="number"
            placeholder="10"
            value={qty}
            onChange={(val) => setqty(val.target.value)}
            onFocus={() => seterror1(false)}
          />
        </div>
        <div className="w-full md:w-1/6 px-3 my-2">
          <label
            className={
              error2
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            نرخی گەڕاوە
          </label>
          <input
            className={
              error2
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="number"
            placeholder="100"
            value={price}
            onChange={(val) => setprice(val.target.value)}
            onFocus={() => seterror2(false)}
          />
        </div>
        <div className="w-full md:w-1/6 px-3 mt-1">
          <label
            for="cate"
            className={error3?"block mb-2 text-sm font-medium text-red-900":"block mb-2 text-sm font-medium text-gray-900"}
          >
            کۆمپانیای دابینکەر
          </label>
          <select
            id="cate"
            className={error3?
              "bg-gray-50 border border-red-300 text-red-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4":
              "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
            }
            value={company_id}
            onChange={(val) => {
              setcompany_id(val.target.value);
              seterror3(false)
            }}
          >
            <option value="">کۆمپانیا</option>
            {initState.company.map((lis) => (
              <option value={lis.company_id}>{lis.company_name}</option>
            ))}
          </select>
        </div>
        <div className="w-full md:w-1/6 px-3 my-2">
          <label
            className={
              "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            تێبینی
          </label>
          <input
            className={
            "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="Note"
            value={note}
            onChange={(val) => setnote(val.target.value)}
          />
        </div>
        <div className="w-full md:w-1/6 px-3 mt-1">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={() => {
              if (barcode == "" || !barcode) {
                seterror(true);
                return false;
              }
              if (qty <0 || !qty) {
                seterror1(true);
                return false;
              }
              if (price <0 || !price) {
                seterror2(true);
                return false;
              }
              if (company_id == "" || !company_id) {
                seterror3(true);
                return false;
              }
              createaction(barcode,qty,price,initState.token.id,company_id,note)(recaller)(setrecaller)(initDispatch)(
                clear
              );
            }}
          >
            زیادکردن
          </button>
        </div>
        </div>
        <Return_item_companylist />
    </div>
  )
}

export default Return_item_company