import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import Editcategorymodal from "./editcategorymodal";

const Categorylist = () => {
  const { initState, recaller, setrecaller, clear, initDispatch } =
    useContext(GlobalContext);

  const [category_id, setcategory_id] = useState();
  const [category_name, setcategory_name] = useState();
  const [show, setshow] = useState(false);
  return (
    <div>
      <Editcategorymodal
        show={show}
        setshow={setshow}
        category_id={category_id}
        category_name={category_name}
        setcategory_name={setcategory_name}
      />
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    نوع
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    تبديل
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {initState.category.map((lis) => (
                    <tr key={lis.category_id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.category_name}
                        </p>
                      </td>
                      <td
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm cursor-pointer"
                        onClick={() => {
                          setcategory_id(lis.category_id);
                          setcategory_name(lis.category_name);
                          setshow(true);
                        }}
                      >
                        <span
                          className={
                            "relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight"
                          }
                        >
                          <span
                            aria-hidden
                            className={
                              "absolute inset-0 bg-orange-400 opacity-50 rounded-md"
                            }
                          ></span>
                          <span className="relative">تغير</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categorylist;
