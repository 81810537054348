import { useaxios } from "../../../utils/useaxios";
export default (sell_invoice_id) => recaller => setrecaller => initDispatch => clear => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getMinutes();
    initDispatch({ type: 'loadingTrue' });
    useaxios('sell_invoice/delete.php', { sell_invoice_id }).then((res) => {
        if (res.status == "ok") {
            initDispatch({ type: 'setreturnbybarcode', payload: [] });
            setrecaller(recaller + 1);
        } else {
            // clear();
        }
        // setloading(false);
    });
}