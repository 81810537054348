import { useaxios } from "../../../utils/useaxios";
export default (expense_type_name) => recaller => setrecaller => initDispatch => clear => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    initDispatch({ type: 'loadingTrue' });
    useaxios('expense_type/create.php', { expense_type_name }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setrecaller(recaller + 1);
        } else {
            clear();
        }
        // setloading(false);
    });
}