import { useaxios } from "../../../utils/useaxios";
export default (from, to, barcode, company_id) => recaller => setrecaller => initDispatch => clear => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // initDispatch({ type: 'loadingTrue' });
    useaxios('company_invoice/read.php', { from, to, barcode, company_id }).then((res) => {
        if (res.status == "ok") {
            initDispatch({ type: 'setcompanyinvoiceitem', payload: res.data });
            // setrecaller(recaller + 1);
        } else {
            // clear();
        }
        // setloading(false);
    });
}