import React from "react";
import "./css/loading.scss";

const Loading = () => {
  return (
    <div className="spinner">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div style={{ color: "#00539f" }}>Loading...</div>
    </div>
  );
};

export default Loading;
