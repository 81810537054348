import { useaxios } from "../../../utils/useaxios";
export default (text) => setname => setprice => {
    // initDispatch({ type: 'loadingTrue' });
    useaxios('item/getitem.php', { text }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setname(res.data[0].item_name);
            setprice(res.data[0].item_price);
        } else {
            setname('');
            setprice('')
            alert('کاڵا نەدۆزرایەوە');
        }
        // setloading(false);
    });
}