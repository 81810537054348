import React, { useState, useContext ,useRef} from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/barcode/createaction";
import Barcode from 'react-barcode';
import './barcodeprint.css'
import getitemaction from "../../context/action/barcode/getitemaction";

const Barcodee = () => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
    
    const [barcode,setbarcode]=useState();
    const [name,setname]=useState();
    const [price,setprice]=useState();

    const [error,seterror]=useState(false);
    const [error1,seterror1]=useState(false);
  return (
    <div>
        <div className="w-full h-20 flex justify-center items-center text-3xl barcode_print_hide">
            باڕکۆد
        </div>
        <div className="w-full flex barcode_print_hide">
        <div className="w-full md:w-1/6 px-3 my-2">
          <label
            className={
              error1
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            باڕکۆد
          </label>
          <input
            className={
              error1
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="number"
            placeholder="3.14"
            value={barcode}
            onChange={(val) => setbarcode(val.target.value)}
            onFocus={() => seterror1(false)}
            onKeyDown={(key) => {
              if (key.key == "Enter") {
                getitemaction(barcode)(setname)(setprice);
              }
            }}
          />
        </div>
        <div className="w-full md:w-1/6 px-3 my-2">
          <label
            className={
              "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            اسم صنف
          </label>
          <input
            className={
            "appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="3.14"
            value={name}
            disabled={true}
            onChange={(val) => setname(val.target.value)}
          />
        </div>
        <div className="w-full md:w-1/6 px-3 my-2">
          <label
            className={
              "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            سعر
          </label>
          <input
            className={
            "appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="3.14"
            value={price}
            disabled={true}
            onChange={(val) => setprice(val.target.value)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 mt-1">
          <button
            className="bg-zinc-700 hover:bg-zinc-900 text-white font-bold py-3 px-4 rounded mt-7"
            onClick={() => {
                if (barcode == "" || !barcode) {
                    seterror1(true);
                    return false;
                  }
                  window.print();
            }}
          >
            پرینت
          </button>
        </div>
        </div>
        <div className="barcode_show">
          {/* <div className="w-full h-full flex flex-wrap justify-center items-stretch"> */}
          <div className="w-full px-3 mt-[5%] flex justify-center text-9xl">
            {name}
          </div>
          <div className="w-full flex justify-center text-9xl">
            {price}
          </div>
          <div className="flex mt-[1%] justify-center">
            <Barcode value={barcode} width={8} height={400} fontSize={80} />
          </div>
          {/* </div> */}
        </div>
    </div>
  )
}

export default Barcodee