import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import changestate from "../../context/action/user/changestate";
import Editusermodal from "./editusermodal";

const Userlist = () => {
  const { initState, recaller, setrecaller, clear, initDispatch } =
    useContext(GlobalContext);

  const [user_id, setuser_id] = useState();
  const [user_name, setuser_name] = useState();
  const [user_password, setuser_password] = useState();
  const [user_role, setuser_role] = useState();
  const [discount_attemps, setdiscount_attemps] = useState(100);
  const [discount_range, setdiscount_range] = useState(100);
  const [show, setshow] = useState(false);

  return (
    <div>
      <Editusermodal
        show={show}
        setshow={setshow}
        user_id={user_id}
        user_name={user_name}
        setuser_name={setuser_name}
        user_password={user_password}
        setuser_password={setuser_password}
        user_role={user_role}
        setuser_role={setuser_role}
        discount_attemps={discount_attemps}
        setdiscount_attemps={setdiscount_attemps}
        discount_range={discount_range}
        setdiscount_range={setdiscount_range}
      />
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    اسم
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    دور
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    تخفيظات الشهرية
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    نسبة التخفيظ
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    حالة
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {initState.user.map((lis) => (
                    <tr
                      className="cursor-pointer"
                      onDoubleClick={() => {
                        setuser_id(lis.user_id);
                        setuser_name(lis.user_name);
                        setuser_password(lis.user_password);
                        setuser_role(lis.user_role);
                        setdiscount_attemps(lis.discount_attemp);
                        setdiscount_range(lis.discount_range);
                        setshow(true);
                      }}
                    >
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.user_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.user_role}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.user_role=='admin'?"Full":lis.discount_attemp}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.user_role=='admin'?"Full":lis.discount_range}
                        </p>
                      </td>
                      <td
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        onClick={() => {
                          changestate(lis.user_id, lis.user_state)(recaller)(
                            setrecaller
                          )(initDispatch)(clear);
                        }}
                      >
                        <span
                          className={
                            lis.user_state == "active"
                              ? "relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                              : "relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight"
                          }
                        >
                          <span
                            aria-hidden
                            className={
                              lis.user_state == "active"
                                ? "absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                : "absolute inset-0 bg-red-200 opacity-50 rounded-full"
                            }
                          ></span>
                          <span className="relative">{lis.user_state}</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userlist;
