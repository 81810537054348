import { useaxios } from "../../../utils/useaxios";
export default (category_id) => recaller => setrecaller => initDispatch => clear => settype => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // initDispatch({ type: 'loadingTrue' });
    useaxios('report/jard.php', { category_id }).then((res) => {
        if (res.status == "ok") {
            console.log(res.data);
            initDispatch({ type: 'setstok', payload: res.data });
            settype('jard');
            // setrecaller(recaller + 1);
        } else {
            // clear();
        }
        // setloading(false);
    });
}