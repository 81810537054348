import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import numberbeautify from "../../utils/numberbeautify";
import Companyinvoicemodal from "./companyinvoicemodal";
import Companyloanmodal from "./companyloanmodal";

const Companytable = () => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
    const [invoice,setinvoice]=useState([]);
    const [loan,setloan]=useState([]);

    const [show,setshow]=useState(false);
    const [show1,setshow1]=useState(false);
  return (
    <div>
      <Companyinvoicemodal show={show} setshow={setshow} invoice={invoice} />
      <Companyloanmodal show={show1} setshow={setshow1} loan={loan} />
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    ناوی کۆمپانیا
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    ژمارە
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    قەرز
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    وەسڵی کڕینەکان
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    وەسڵی دانەوەی قەرز
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {initState.company_report.map((lis)=>(
                    <tr key={lis.company_id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {lis.company_name}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {lis.company_phone}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                       {numberbeautify(lis.company_loan)}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                    <button type="button" onClick={()=>{setinvoice(lis.invoice);setshow(true);}} className="focus:outline-none text-white bg-slate-600 hover:bg-slate-900 focus:ring-4 focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">کڕاو</button>
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                    <button type="button" onClick={()=>{setloan(lis.loan_out);setshow1(true);}} className="focus:outline-none text-white bg-slate-600 hover:bg-slate-900 focus:ring-4 focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">قەرز</button>
                    </p>
                  </td>
                  </tr>
                  ))}
                </tbody>
                </table>
                </div>
                </div>
                </div>
                </div>
    </div>
  )
}

export default Companytable