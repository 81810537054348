import { useaxios } from "../../../utils/useaxios";
export default (id, role) => initDispatch => clear => {
    initDispatch({ type: 'loadingTrue' });
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('init.php', { id, role, today }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            initDispatch({ type: 'setinit', payload: { user: res.user, role: res.role, expense_type: res.expense_type, active_expense_type: res.active_expense_type, expense: res.expense, category: res.category, company: res.company, item: res.item, today_invoice: res.today_invoice, return_item_company: res.return_item_company, removeditem: res.removeditem, discount_attemp: Number(res.discound_attemp_remain), discount_range: Number(res.discound_range) } });
        } else {
            clear();
        }
    });
}