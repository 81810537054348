import React,{useContext} from "react";
import { Link, useLocation } from "react-router-dom";
import { BiHomeAlt } from "react-icons/bi";
import { TbBrandAsana, TbFileInvoice,TbTruckReturn } from "react-icons/tb";
import { BiCategory } from "react-icons/bi";
import { SiApostrophe } from "react-icons/si";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FiShoppingCart } from "react-icons/fi";
import { MdOutlineAnalytics } from "react-icons/md";
import { RiCloseFill ,RiDeleteBin3Line} from "react-icons/ri";
import { AiOutlinePound } from "react-icons/ai";
import { IoStorefrontOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/gi";
import { GlobalContext } from "../context/provider";
import { ImBarcode } from "react-icons/im";
import { IoMdReturnLeft } from "react-icons/io";
import './css/onprintcss.css'

const Menu = ({showMenu, setshowMenu}) => {
  const location = useLocation();
  const {initState}=useContext(GlobalContext);
  return (
    <div className="h-screen print_hide">
      {showMenu&&<div className="fixed top-6 left-5">
        <RiCloseFill size={35} onClick={()=>{setshowMenu(!showMenu)}} />
      </div>}
      <div className="w-full h-24 mb-20 flex justify-center items-center">
        SSKM
      </div>
      <div className="w-full pr-10 overflow-y-auto">
        <div>Menu</div>
        <div className="mt-3">
          {initState.role=="admin"||initState.role=="casher"?
          <div
            className={
              location.pathname == "/"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/"
              className={
                location.pathname == "/"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <FiShoppingCart size={21} /> <p className="pr-2">مبعيات</p>
            </Link>
          </div>:null}
          {initState.role=="admin"||initState.role=="casher"?
          <div
            className={
              location.pathname == "/returnbybarcode"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/returnbybarcode"
              className={
                location.pathname == "/returnbybarcode"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <IoMdReturnLeft size={21} /> <p className="pr-2">تبديل</p>
            </Link>
          </div>
            :null}
              {/* {initState.token.role=='admin'? */}
              <div>
          {/* <div
            className={
              location.pathname == "/company"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/company"
              className={
                location.pathname == "/company"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {}}
            >
              <RiBuildingLine size={21} /> <p className="pr-2">کۆمپانیا</p>
            </Link>
          </div> */}
          {initState.role=="admin"?
          <div
            className={
              location.pathname == "/category"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/category"
              className={
                location.pathname == "/category"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <BiCategory size={21} /> <p className="pr-2">نوع</p>
            </Link>
          </div>:null}
          {initState.role=="admin"?
          <div
            className={
              location.pathname == "/item"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/item"
              className={
                location.pathname == "/item"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <TbBrandAsana size={21} /> <p className="pr-2">صنف</p>
            </Link>
          </div>:null}
          {initState.role=="admin"?
          <div
            className={
              location.pathname == "/removeditem"
                ? "hidden md:flex pr-3 w-full h-12 bg-slate-600 rounded-l-3xl items-center"
                : "hidden md:flex pr-3 w-full h-12 items-center"
            }
          >
            <Link
              to="/removeditem"
              className={
                location.pathname == "/removeditem"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <RiDeleteBin3Line size={21} /> <p className="pr-2">قائمة البضاعة المحذوفة</p>
            </Link>
          </div>:null}
          {initState.role=="admin"?
          <div
            className={
              location.pathname == "/buy"
                ? "hidden md:flex pr-3 w-full h-12 bg-slate-600 rounded-l-3xl items-center"
                : "hidden md:flex pr-3 w-full h-12 items-center"
            }
          >
            <Link
              to="/buy"
              className={
                location.pathname == "/buy"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <IoStorefrontOutline size={21} /> <p className="pr-2">شراء</p>
            </Link>
          </div>:null}
          {initState.role=="admin"?
          <div
            className={
              location.pathname == "/buyinvoice"
                ? "hidden md:flex pr-3 w-full h-12 bg-slate-600 rounded-l-3xl items-center"
                : "hidden md:flex pr-3 w-full h-12 items-center"
            }
          >
            <Link
              to="/buyinvoice"
              className={
                location.pathname == "/buyinvoice"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <TbFileInvoice size={21} /> <p className="pr-2">وصل المشتريات</p>
            </Link>
          </div>:null}
          {initState.role=="admin"?
          <div
            className={
              location.pathname == "/expensetype"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/expensetype"
              className={
                location.pathname == "/expensetype"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <SiApostrophe size={21} /> <p className="pr-2">نوع المصاريف</p>
            </Link>
          </div>:null}
          {initState.role=="admin"?
          <div
            className={
              location.pathname == "/expense"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/expense"
              className={
                location.pathname == "/expense"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <AiOutlinePound size={21} /> <p className="pr-2">المصاريف</p>
            </Link>
          </div>:null}
          {/* <div
            className={
              location.pathname == "/returnitemtocompany"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/returnitemtocompany"
              className={
                location.pathname == "/returnitemtocompany"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {}}
            >
              <TbTruckReturn size={21} /> <p className="pr-2">گەڕانەوەی کاڵا بۆ کۆمپانیا</p>
            </Link>
          </div> */}
          {/* <div
            className={
              location.pathname == "/broke"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/broke"
              className={
                location.pathname == "/broke"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {}}
            >
              <GiBrokenPottery size={21} /> <p className="pr-2">خەسار</p>
            </Link>
          </div> */}
          {initState.role=="admin"?
          <div
            className={
              location.pathname == "/barcode"
                ? "hidden md:flex pr-3 w-full h-12 bg-slate-600 rounded-l-3xl items-center"
                : "hidden md:flex pr-3 w-full h-12 items-center"
            }
          >
            <Link
              to="/barcode"
              className={
                location.pathname == "/barcode"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <ImBarcode size={21} /> <p className="pr-2">باڕکۆد</p>
            </Link>
          </div>:null}
          {initState.role=="admin"?
          <div
            className={
              location.pathname == "/user"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/user"
              className={
                location.pathname == "/user"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <HiOutlineUserGroup size={21} />{" "}
              <p className="pr-2">المستخدمين</p>
            </Link>
          </div>:null}
          {initState.role=="admin"||initState.role=="monitor"?
          <div
            className={
              location.pathname == "/report"
                ? "pr-3 w-full h-12 bg-slate-600 rounded-l-3xl flex items-center"
                : "pr-3 w-full h-12 flex items-center"
            }
          >
            <Link
              to="/report"
              className={
                location.pathname == "/report"
                  ? "flex text-white"
                  : "flex text-gray-500"
              }
              onClick={() => {setshowMenu(false)}}
            >
              <MdOutlineAnalytics size={21} /> <p className="pr-2">تقرير</p>
            </Link>
          </div>:null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
