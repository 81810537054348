import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import numberbeautify from "../../utils/numberbeautify";

const Itemselltable = () => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
  return (
    <div>
        <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">إجمالي كمية البضائع المباعة</th>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">{initState.item_sell_report_qty}</td>
                </tr>
                <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">مجموع المبيع</th>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">{numberbeautify(initState.item_sell_report_price)}</td>
                </tr>
              </table>
              </div>
              </div>
              </div>
              </div>
    </div>
  )
}

export default Itemselltable