import axios from "axios"
import constant from "./constant";
export const useaxios = async (location, datas) => {
    try {
        var axiosresult = await (await axios.post(constant.host + location, datas));
        if (axiosresult.status == 200) {
            return axiosresult.data;
        } else {
            return 'auth_failed';
        }

    } catch (error) {
        console.log(error);
        return 'auth_failed';
    }
}