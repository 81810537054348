import { useaxios } from "../../../utils/useaxios";
export default (item_barcode, return_item_company_qty, return_item_company_price, user_id, company_id, return_item_company_note) => recaller => setrecaller => initDispatch => clear => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    initDispatch({ type: 'loadingTrue' });
    useaxios('return_item_company/create.php', { item_barcode, return_item_company_qty, return_item_company_price, user_id, company_id, return_item_company_note, return_item_company_date: today }).then((res) => {
        console.log(res);
        if (res.status == "ok") {
            setrecaller(recaller + 1);
        } else if (res.status == "notfound") {
            initDispatch({ type: 'loadingFalse' });
            alert('ئەم کاڵایە نەدۆزرایەوە ');
        } else if (res.status == "outofqty") {
            initDispatch({ type: 'loadingFalse' });
            alert('بڕی دیاریکراو لەم کاڵایە نییە ');
        }
        // setloading(false);
    });
}