import { useaxios } from "../../../utils/useaxios";
export default (loan_out_amount, loan_out_note, company_id, user_id) => recaller => setrecaller => initDispatch => clear => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    initDispatch({ type: 'loadingTrue' });
    useaxios('loan_out/create.php', { loan_out_amount, loan_out_note, company_id, user_id, loan_out_date: today }).then((res) => {
        if (res.status == "ok") {
            setrecaller(recaller + 1);
        } else {
            clear();
        }
        // setloading(false);
    });
}