import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import createaction from "../../context/action/expense/createaction";
import Expenselist from "./expenselist";
import Addexpensemodal from "./addexpensemodal";

const Expense = () => {
  const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
  const [expense_amount, setexpense_amount] = useState();
  const [expense_type_id, setexpense_type_id] = useState("null");
  const [expense_date, setexpense_date] = useState();
  const [expense_note, setexpense_note] = useState();
  const [show,setshow]=useState(false);

  const [error, seterror] = useState(false);
  const [error1, seterror1] = useState(false);
  const [error2, seterror2] = useState(false);

  return (
    <div>
      <Addexpensemodal show={show} setshow={setshow} />
      <div className="w-full h-20 flex justify-center items-center text-3xl">
      المصاريف
      </div>
      <div className="hidden md:flex w-full">
        <div className="w-full md:w-1/5 px-3 my-2">
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            المصاريف
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="number"
            placeholder="3.14"
            value={expense_amount}
            onChange={(val) => setexpense_amount(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className="w-full md:w-1/5 px-3 mt-1">
          <label
            for="cate"
            className={
              error1
                ? "block mb-2 text-sm font-medium text-red-500"
                : "block mb-2 text-sm font-medium text-gray-900"
            }
          >
            نوع المصاريف
          </label>
          <select
            id="cate"
            className={
              error1
                ? "bg-gray-50 border border-red-500 text-red-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
                : "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
            }
            value={expense_type_id}
            onChange={(val) => {
              seterror1(false);
              setexpense_type_id(val.target.value);
            }}
          >
            <option value="null">حدد نوع المصاريف</option>
            {initState.active_expense_type.map((lis) => (
              <option value={lis.expense_type_id}>
                {lis.expense_type_name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full md:w-1/5 px-3 my-2">
          <label
            className={
              error2
              ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
              : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            تاريخ المصاريف
          </label>
          <input
            className={
              error2
              ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="بەرواری خەرجی"
            value={expense_date}
            onChange={(val) => setexpense_date(val.target.value)}
            onFocus={()=>{seterror2(false)}}
          />
        </div>
        <div className="w-full md:w-1/5 px-3 my-2">
          <label
            className={
              "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            ملاحظة
          </label>
          <input
            className={
              "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="ملاحظة"
            value={expense_note}
            onChange={(val) => setexpense_note(val.target.value)}
          />
        </div>
        <div className="w-full md:w-1/5 px-3 mt-1">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={() => {
              if (expense_amount == "" || !expense_amount) {
                seterror(true);
                return false;
              }
              if (expense_type_id == "null" || !expense_type_id) {
                seterror1(true);
                return false;
              }
              if (expense_date == "" || !expense_date) {
                seterror2(true);
                return false;
              }
              createaction(
                expense_amount,
                expense_type_id,
                expense_date,
                expense_note,
                initState.token.id
              )(recaller)(setrecaller)(initDispatch)(clear);
            }}
          >
            زيادة المصاريف
          </button>
        </div>
      </div>
      <div className="flex items-center justify-center lg:hidden w-full h-14">
      <button
            className="w-11/12 h-12 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              setshow(true);
            }}
          >
            زيادة المصاريف
          </button>
      </div>
      <Expenselist />
    </div>
  );
};

export default Expense;
