import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import numberbeautify from "../../utils/numberbeautify";
import Editcompanymodal from "./editcompanymodal";
import Loanbackmodal from "./loanbackmodal";

const Companylist = () => {
  const { initState, recaller, setrecaller, clear, initDispatch } =
    useContext(GlobalContext);

  const [company_id, setcompany_id] = useState();
  const [company_name, setcompany_name] = useState();
  const [company_address, setcompany_address] = useState();
  const [company_phone, setcompany_phone] = useState();
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  return (
    <div>
      <Editcompanymodal
        show={show}
        setshow={setshow}
        company_id={company_id}
        company_name={company_name}
        setcompany_name={setcompany_name}
        company_address={company_address}
        setcompany_address={setcompany_address}
        company_phone={company_phone}
        setcompany_phone={setcompany_phone}
      />
      <Loanbackmodal show={show1} setshow={setshow1} company_id={company_id} />
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      ناوی کۆمپانیا
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      ناونیشان
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      ژمارەی تەلەفۆن
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      قەرزی کۆمپانیا
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      گێڕانەوەی قەرز
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      گۆڕانکاری
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {initState.company.map((lis) => (
                    <tr key={lis.company_id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.company_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.company_address}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.company_phone}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {numberbeautify(lis.company_loan)}
                        </p>
                      </td>
                      <td
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm cursor-pointer"
                        onClick={() => {
                          setcompany_id(lis.company_id);
                          setshow1(true);
                        }}
                      >
                        <span
                          className={
                            "relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                          }
                        >
                          <span
                            aria-hidden
                            className={
                              "absolute inset-0 bg-green-400 opacity-50 rounded-md"
                            }
                          ></span>
                          <span className="relative">گێڕانەوەی قەرز</span>
                        </span>
                      </td>
                      <td
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm cursor-pointer"
                        onClick={() => {
                          setcompany_id(lis.company_id);
                          setcompany_name(lis.company_name);
                          setcompany_address(lis.company_address);
                          setcompany_phone(lis.company_phone);
                          setshow(true);
                        }}
                      >
                        <span
                          className={
                            "relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight"
                          }
                        >
                          <span
                            aria-hidden
                            className={
                              "absolute inset-0 bg-orange-400 opacity-50 rounded-md"
                            }
                          ></span>
                          <span className="relative">گۆڕانکاری</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companylist;
