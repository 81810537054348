export default {
    islogin: false,
    loading: true,
    token: null,
    authcheck: false,
    role: null,
    user: [],
    expense_type: [],
    active_expense_type: [],
    expense: [],
    broke: [],
    category: [],
    company: [],
    item: [],
    removeditem: [],
    today_invoice: [],
    company_invoice_item: [],
    stok: [],
    expense_report: [],
    company_report: [],
    returnbybarcode: [],
    return_item_company: [],
    summary_report_sell_array: [],
    summary_report_expense_amount: 0,
    summary_report_damage_qty: 0,
    summary_report_damage_amount: 0,
    summary_report_return_item_company_qty: 0,
    summary_report_return_item_company_amount: 0,
    total_sell_qty: 0,
    total_sell_price: 0,
    total_return_qty: 0,
    total_return_price: 0,
    total_broke_qty: 0,
    total_broke_price: 0,
    item_sell_report_qty: 0,
    item_sell_report_price: 0,
    discount_attemp: 0,
    discount_range: 0,
}