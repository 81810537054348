import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import changestateaction from "../../context/action/expensetype/changestateaction";
import Editexpensetypemodal from "./editexpensetypemodal";
const Expensetypelist = () => {
  const { initState, recaller, setrecaller, clear, initDispatch } =
    useContext(GlobalContext);

  const [expense_type_id, setexpense_type_id] = useState();
  const [expense_type_name, setexpense_type_name] = useState();
  const [show, setshow] = useState(false);
  return (
    <div>
      <Editexpensetypemodal
        show={show}
        setshow={setshow}
        expense_type_id={expense_type_id}
        expense_type_name={expense_type_name}
        setexpense_type_name={setexpense_type_name}
      />
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    زيادة نوع المصاريف
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    حالة
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    تغير
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {initState.expense_type.map((lis) => (
                    <tr key={lis.expense_type_id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.expense_type_name}
                        </p>
                      </td>
                      <td
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm cursor-pointer"
                        onClick={() => {
                          changestateaction(
                            lis.expense_type_id,
                            lis.expense_type_state
                          )(recaller)(setrecaller)(initDispatch)(clear);
                        }}
                      >
                        <span
                          className={
                            lis.expense_type_state == "active"
                              ? "relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                              : "relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight"
                          }
                        >
                          <span
                            aria-hidden
                            className={
                              lis.expense_type_state == "active"
                                ? "absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                : "absolute inset-0 bg-red-200 opacity-50 rounded-full"
                            }
                          ></span>
                          <span className="relative">
                            {lis.expense_type_state}
                          </span>
                        </span>
                      </td>
                      <td
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm cursor-pointer"
                        onClick={() => {
                          setexpense_type_id(lis.expense_type_id);
                          setexpense_type_name(lis.expense_type_name);
                          setshow(true);
                        }}
                      >
                        <span
                          className={
                            "relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight"
                          }
                        >
                          <span
                            aria-hidden
                            className={
                              "absolute inset-0 bg-orange-400 opacity-50 rounded-md"
                            }
                          ></span>
                          <span className="relative">تغير</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expensetypelist;
