import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import numberbeautify from "../../utils/numberbeautify";
import returnitemtostockaction from "../../context/action/item/returnitemtostockaction";
const Deleteditem = () => {
    const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
  return (
    <div>
        <div className="w-full h-20 flex justify-center items-center text-3xl">
        قائمة البضاعة المحذوفة
      </div>
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    اسم صنف
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      باڕکۆد
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    سعر
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    عدد المتبقي
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    نوع صنف
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    ارجع علئ مخزن
                    </th>
                  </tr>
                </thead>
                <tbody>
                    {initState.removeditem.map((lis)=>(
                        <tr
                        key={lis.item_id}
                        className={"bg-white"}
                      >
                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.item_name}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200  text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.item_barcode}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200  text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {numberbeautify(lis.item_price)}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200  text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.item_qty}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200  text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.category_name}
                          </p>
                        </td>
                        <td
                          className="px-5 py-5 border-b border-gray-200  text-sm cursor-pointer"
                          onClick={() => {
                            if (
                                window.confirm(
                                  "إشعار بإرجاع البضائع إلى المستودع. هل أنت متأكد؟"
                                ) == true
                              ) {
                              returnitemtostockaction(lis.item_id)(recaller)(setrecaller)(initDispatch)(clear)
                            }
                          }}
                        >
                          <span
                            className={
                              "relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                            }
                          >
                            <span
                              aria-hidden
                              className={
                                "absolute inset-0 bg-green-400 opacity-50 rounded-md"
                              }
                            ></span>
                            <span className="relative">ارجع</span>
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
                </table>
                </div>
                </div>
                </div>
                </div>
    </div>
  )
}

export default Deleteditem