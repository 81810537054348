import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import deleteaction from "../../context/action/return_item_company/deleteaction";

const Return_item_companyeditmodal = props => {
    const { initState,recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

    const [error,seterror]=useState(false);
    const [error1,seterror1]=useState(false);
    const [error2,seterror2]=useState(false);
    const [error3,seterror3]=useState(false);
  return (
    <>
        {props.show ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative  my-6 mx-auto md:w-2/3 lg:w-1/2">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl uppercase font-semibold">
                      گۆڕانکاری
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => props.setshow(false)}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        X
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="flex flex-wrap w-full">
                      <div className="w-full md:w-full px-3">
                        <label
                          className={
                            error
                              ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                              : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          }
                          for="grid-last-name"
                        >
                          باڕکۆد
                        </label>
                        <input
                          className={
                            error
                              ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          }
                          id="grid-last-name"
                          type="text"
                          placeholder="1234"
                          value={props.barcode}
                          onChange={(val) =>
                            props.setbarcode(val.target.value)
                          }
                          onFocus={() => seterror(false)}
                        />
                      </div>
                      <div className="w-full md:w-full px-3">
                        <label
                          className={
                            error1
                              ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                              : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          }
                          for="grid-last-name"
                        >
                          بڕی گەڕاوە
                        </label>
                        <input
                          className={
                            error1
                              ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          }
                          id="grid-last-name"
                          type="number"
                          placeholder="10"
                          value={props.return_item_company_qty}
                          onChange={(val) =>
                            props.setreturn_item_company_qty(val.target.value)
                          }
                          onFocus={() => seterror1(false)}
                        />
                      </div>
                      <div className="w-full md:w-full px-3">
                        <label
                          className={
                            error2
                              ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                              : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          }
                          for="grid-last-name"
                        >
                          نرخی گەڕاوە
                        </label>
                        <input
                          className={
                            error2
                              ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          }
                          id="grid-last-name"
                          type="number"
                          placeholder="100"
                          value={props.return_item_company_price}
                          onChange={(val) =>
                            props.setreturn_item_company_price(val.target.value)
                          }
                          onFocus={() => seterror2(false)}
                        />
                      </div>
                      <div className="w-full md:w-full px-3">
                        <label
                            for="cate"
                            className={error3?"block mb-2 text-sm font-medium text-red-900":"block mb-2 text-sm font-medium text-gray-900"}
                        >
                            کۆمپانیای دابینکەر
                        </label>
                        <select
                            id="cate"
                            className={error3?
                            "bg-gray-50 border border-red-300 text-red-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4":
                            "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-4"
                            }
                            value={props.company_id}
                            onChange={(val) => {
                            props.setcompany_id(val.target.value);
                            seterror3(false)
                            }}
                        >
                            {initState.company.map((lis) => (
                            <option value={lis.company_id}>{lis.company_name}</option>
                            ))}
                        </select>
                        </div>
                      <div className="w-full md:w-full px-3">
                        <label
                          className={
                             "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          }
                          for="grid-last-name"
                        >
                          تێبینی
                        </label>
                        <input
                          className={
                            "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          }
                          id="grid-last-name"
                          type="text"
                          placeholder="Note"
                          value={props.return_item_company_note}
                          onChange={(val) =>
                            props.setreturn_item_company_note(val.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        if (
                          window.confirm("ئاگاداری ڕەشکردنەوە . دڵنیای ؟") ==
                          true
                        ) {
                          deleteaction(props.return_item_company_id)(recaller)(
                            setrecaller
                          )(initDispatch)(clear);
                        }
                      }}
                    >
                      ڕەشکردنەوە
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
  )
}

export default Return_item_companyeditmodal