import { useaxios } from "../../../utils/useaxios";
export default (barcode) => recaller => setrecaller => initDispatch => setbarcode => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('returnbybarcode/getinvoice.php', { barcode }).then((res) => {
        console.log(res);
        setbarcode('');
        if (res.status == "ok") {
            initDispatch({ type: 'setreturnbybarcode', payload: res.data })
        } else {
            initDispatch({ type: 'setreturnbybarcode', payload: [] })
            window.alert('هیچ وەسڵێک بەم ژمارەیە نەدۆزرایەوە');
        }
    });
}