import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import { MdDeleteForever } from "react-icons/md";
import numberbeautify from "../../utils/numberbeautify";
import deleteaction from "../../context/action/buy/deleteaction";
import getitemaction from "../../context/action/item/getitemaction";
import updateaction from "../../context/action/buy/updateaction";

const Buyinvoicedetailmodal = (props) => {
  const { initState, recaller, setrecaller, clear, initDispatch } =
    useContext(GlobalContext);

  const [text, settext] = useState();
  return (
    <>
      {props.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto  md:w-3/4 lg:w-3/4">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl uppercase font-semibold">
                  تغير
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setshow(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      X
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative h-[75vh] overflow-y-auto p-6 flex-auto">
                  <div className="flex flex-wrap w-full">
                    <div className="w-full md:w-full px-3 my-2">
                      <label
                        className={
                          "block uppercase tracking-wide text-white text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        اسم صنف / باڕکۆد
                      </label>
                      <input
                        className={
                          "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder="3.14"
                        value={text}
                        onChange={(val) => settext(val.target.value)}
                        onKeyDown={(key) => {
                          if (key.key == "Enter") {
                            getitemaction(text)(recaller)(setrecaller)(
                              props.items
                            )(props.setitems)(settext);
                          }
                        }}
                      />
                    </div>
                    <table className="min-w-full leading-normal">
                      <thead>
                        <tr>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          اسم صنف
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            باڕکۆد
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          سعر الشراء
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            عدد
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            حذف
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.items.map((lis, index) => (
                          <tr key={index}>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {lis.item_name}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {lis.item_barcode}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <input
                                className={
                                  "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                }
                                id="grid-last-name"
                                type="number"
                                placeholder="3.14"
                                value={lis.company_invoice_item_price}
                                onChange={(val) => {
                                  props.setitems(
                                    props.items.map((lis, indexx) =>
                                      index == indexx
                                        ? {
                                            item_id: lis.item_id,
                                            item_name: lis.item_name,
                                            item_barcode: lis.item_barcode,
                                            company_invoice_item_qty:
                                              lis.company_invoice_item_qty,
                                            company_invoice_item_price:
                                              val.target.value,
                                            company_invoice_item_exp_date:
                                              lis.company_invoice_item_exp_date,
                                          }
                                        : lis
                                    )
                                  );
                                }}
                              />
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <input
                                className={
                                  "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                }
                                id="grid-last-name"
                                type="number"
                                placeholder="3.14"
                                value={lis.company_invoice_item_qty}
                                onChange={(val) => {
                                  props.setitems(
                                    props.items.map((lis, indexx) =>
                                      index == indexx
                                        ? {
                                            item_id: lis.item_id,
                                            item_name: lis.item_name,
                                            item_barcode: lis.item_barcode,
                                            company_invoice_item_qty:
                                              val.target.value,
                                            company_invoice_item_price:
                                              lis.company_invoice_item_price,
                                            company_invoice_item_exp_date:
                                              lis.company_invoice_item_exp_date,
                                          }
                                        : lis
                                    )
                                  );
                                }}
                              />
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                <MdDeleteForever
                                  size={28}
                                  color="#e74c3c"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    props.setitems(
                                      props.items.filter(
                                        (list) => list.item_id != lis.item_id
                                      )
                                    );
                                  }}
                                />
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (
                        window.confirm("إشعار الحذف. هل أنت متأكد؟") == true
                      ) {
                        deleteaction(props.company_invoice_id)(recaller)(
                          setrecaller
                        )(initDispatch)(clear);
                      }
                    }}
                  >
                    حذف الوصل
                  </button>
                  <button
                    className={
                      props.items.length > 0
                        ? "bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        : "bg-emerald-300 text-white active:bg-emerald-300 font-bold uppercase text-sm px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    }
                    type="button"
                    disabled={props.items.length > 0 ? false : true}
                    onClick={() => {
                      updateaction(
                        props.company_invoice_id,
                        props.items
                      )(recaller)(setrecaller)(initDispatch)(clear);
                    }}
                  >
                    يتغير الوصل
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Buyinvoicedetailmodal;
