import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import Additemmodal from "./additemmodal";
import Itemlist from "./itemlist";

const Item = () => {
  const [show, setshow] = useState(false);

  return (
    <div>
      <Additemmodal show={show} setshow={setshow} />
      <div className="w-full h-20 flex justify-center items-center text-3xl">
      صنف
      </div>
      <div className="w-full flex">
        <div className="w-full md:w-1/4 px-3 mt-1">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={() => {
              setshow(true);
            }}
          >
            زيادة صنف
          </button>
        </div>
      </div>
      <Itemlist />
    </div>
  );
};

export default Item;
