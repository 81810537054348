import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import getaction from "../../context/action/return_item_company/getaction";
import Return_item_companyeditmodal from "./return_item_companyeditmodal";
import numberbeautify from "../../utils/numberbeautify";

const Return_item_companylist = () => {
    const { initState,recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);

    const [from,setfrom]=useState();
    const [to,setto]=useState();

    const [show,setshow]=useState(false);
    const [error,seterror]=useState(false);
    const [error1,seterror1]=useState(false);

    const [return_item_company_id,setreturn_item_company_id]=useState();
    const [return_item_company_qty,setreturn_item_company_qty]=useState();
    const [return_item_company_price,setreturn_item_company_price]=useState();
    const [barcode,setbarcode]=useState();
    const [company_id,setcompany_id]=useState();
    const [return_item_company_note,setreturn_item_company_note]=useState();

  return (
    <div>
    <Return_item_companyeditmodal show={show} setshow={setshow} return_item_company_id={return_item_company_id} setreturn_item_company_id={setreturn_item_company_id} return_item_company_qty={return_item_company_qty} setreturn_item_company_qty={setreturn_item_company_qty} return_item_company_price={return_item_company_price} setreturn_item_company_price={setreturn_item_company_price} barcode={barcode} setbarcode={setbarcode} company_id={company_id} setcompany_id={setcompany_id} return_item_company_note={return_item_company_note} setreturn_item_company_note={setreturn_item_company_note} />
        <div className="w-full h-20 flex justify-center items-center text-3xl">
        لیستی کاڵای گەڕاوە
      </div>
      <div className="w-full flex">
      <div className="w-full md:w-2/5 px-3 my-2">
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-700 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            لە بەرواری{" "}
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-red-700 border border-red-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={from}
            onChange={(val) => setfrom(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className="w-full md:w-2/5 px-3 my-2">
          <label
            className={
              error1
                ? "block uppercase tracking-wide text-red-700 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            بۆ بەرواری{" "}
          </label>
          <input
            className={
              error1
                ? "appearance-none block w-full bg-gray-50 text-red-700 border border-red-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={to}
            onChange={(val) => setto(val.target.value)}
            onFocus={() => seterror1(false)}
          />
        </div>
        <div className="w-full md:w-1/6 px-3 mt-1">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={() => {
              if (from == "" || !from) {
                seterror(true);
                return false;
              }
              if (to == "" || !to) {
                seterror1(true);
                return false;
              }
              getaction(from,to)(recaller)(setrecaller)(initDispatch)(
                clear
              );
            }}
          >
            گەڕان
          </button>
        </div>
        </div>
        {initState.return_item_company.length>0?<div>
          <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      ناوی کاڵا
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      باڕکۆد
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    بڕی گەڕاوە
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    نرخی گەڕاوە
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    بەروار
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    کۆمپانیا
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    تێبینی
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {initState.return_item_company.map((lis)=>(
                    <tr key={lis.return_item_company_id}
                    className="cursor-pointer"
                      onClick={()=>{
                        setreturn_item_company_id(lis.return_item_company_id);
                        setreturn_item_company_qty(lis.return_item_company_qty);
                        setreturn_item_company_price(lis.return_item_company_price);
                        setbarcode(lis.item_barcode);
                        setcompany_id(lis.company_id);
                        setreturn_item_company_note(lis.return_item_company_note);
                        setshow(true);
                      }}
                    >
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.item_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.item_barcode}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.return_item_company_qty}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {numberbeautify(lis.return_item_company_price)}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.return_item_company_date}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.company_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {lis.return_item_company_note}
                        </p>
                      </td>
                  </tr>
                  ))}
                </tbody>
                </table>
                </div>
                </div>
                </div>
                </div>
        </div>:null}
    </div>
  )
}

export default Return_item_companylist