import React, { useContext, useState } from "react";
import { GlobalContext } from "../context/provider";
import checkaction from "../context/action/login/checkaction";

const Login = () => {
  const { initState, initDispatch, recaller, setrecaller } =
    useContext(GlobalContext);

  const [username, setusername] = useState();
  const [password, setpassword] = useState();

  const [error, seterror] = useState(false);
  const [error1, seterror1] = useState(false);
  return (
    <div className="w-full h-[100vh]">
      <div className="w-full h-[50%] items-center flex justify-center">
        {/* <img src={logo} className='w-40 h-40' /> */}
      </div>
      <div className="w-full h-[50%] flex flex-col items-center">
        <div className="w-full md:w-1/2 px-3 my-2">
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            Username
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="text"
            placeholder="Ex. Jone Doe"
            value={username}
            onChange={(val) => setusername(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className="w-full md:w-1/2 px-3 my-2">
          <label
            className={
              error1
                ? "block uppercase tracking-wide text-red-500 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-namee"
          >
            Password
          </label>
          <input
            className={
              error1
                ? "appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-namee"
            type="password"
            placeholder="********"
            value={password}
            onChange={(val) => setpassword(val.target.value)}
            onFocus={() => seterror1(false)}
          />
        </div>
        <div className="w-full md:w-1/2 px-3 my-2">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              if (username == "" || !username) {
                seterror(true);
                return false;
              }
              if (password == "" || !password) {
                seterror1(true);
                return false;
              }
              initDispatch({ type: "authcheckFalse" });
              checkaction(username, password)(initDispatch)(recaller)(
                setrecaller
              );
              // checkaction(username,password)(initDispatch)
            }}
          >
            Login
          </button>
        </div>
      </div>
      <div
        className={
          initState.authcheck
            ? "w-full absolute bottom-20 flex justify-center items-center font-bold text-2xl text-red-600"
            : "hidden"
        }
      >
        Username Or Password Incorrect
      </div>
    </div>
  );
};

export default Login;
