import { useaxios } from "../../../utils/useaxios";
export default (user_name, user_password) => initDispatch => recaller => setrecaller => {
    initDispatch({ type: 'loadingTrue' });
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    useaxios('login.php', { user_name, user_password }).then((res) => {
        if (res.status == "ok") {
            var tok = {
                id: res.token,
                username: res.username,
                role: res.role
            }
            localStorage.setItem('sskmtoken', JSON.stringify(tok));
            setrecaller(recaller + 1);
            // initDispatch({ type: 'settoken', payload: res.detail.admin_id });

        } else {
            initDispatch({ type: 'authcheckTrue' });
        }
        initDispatch({ type: 'loadingFalse' });
    });
}