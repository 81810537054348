import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import { MdDeleteForever } from "react-icons/md";
import getitemaction from "../../context/action/buy/getitemaction";
import numberbeautify from "../../utils/numberbeautify";
import createaction from "../../context/action/sell/createaction";
import Sellinvoicedetailmodal from "./sellinvoicedetailmodal";
import getinvoiceaction from "../../context/action/sell/getinvoiceaction";
import '../../component/css/onprintcss.css';
import Sellitemprint from "./sellitemprint";

const Sell = () => {
  const { initState, recaller, setrecaller, initDispatch, clear } =
    useContext(GlobalContext);
  const [items, setitems] = useState([]);
  const [text, settext] = useState();
  const [note, setnote] = useState();
  const [discount, setdiscount] = useState(0);
  const [show, setshow] = useState(false);
  const [invoice_detail, setinvoice_detail] = useState();
  const [from, setfrom] = useState();
  const [invoiceno, setinvoiceno] = useState(0);
  
  const [stopsell, setstopsell] = useState(false);
  const [error, seterror] = useState(false);

  const printit=(invoiceno)=>{
    setinvoiceno(invoiceno);
    setTimeout(() => {
      window.print();
    }, 500);
  }
  const discountfunc=()=>{

  }
  return (
    <div>
      <Sellitemprint username={initState.token.username} items={invoice_detail?invoice_detail.items:items} invoiceno={invoiceno} />
      <div className="print_hide">
      <Sellinvoicedetailmodal
        show={show}
        setshow={setshow}
        invoice_detail={invoice_detail}
        setinvoice_detail={setinvoice_detail}
        printit={printit}
      />
      <div className="w-full h-20 flex justify-center items-center text-3xl">
      مبعيات
      </div>
      <div className="w-full flex">
        <div className="w-full md:w-9/12 bg-gray-400 mr-0 md:mr-10 mt-7 rounded-md p-2 flex">
          <div className="w-full md:w-2/3 px-3 my-2">
            <label
              className={
                "block uppercase tracking-wide text-white text-xs font-bold mb-2"
              }
              for="grid-last-name"
            >
              اسم الصنف / باڕکۆد
            </label>
            <input
              className={
                "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              }
              id="grid-last-name"
              type="text"
              placeholder="3.14"
              value={text}
              onChange={(val) => settext(val.target.value)}
              autoFocus
              onKeyDown={(key) => {
                if (key.key == "Enter") {
                  getitemaction(text)(recaller)(setrecaller)(items)(setitems)(
                    settext
                  );
                }
              }}
            />
          </div>
          <div className="w-full md:w-1/3 px-3 my-2">
            <label
              className={
                "block uppercase tracking-wide text-white text-xs font-bold mb-2"
              }
              for="grid-last-name"
            >
              ملاحظة
            </label>
            <input
              className={
                "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              }
              id="grid-last-name"
              type="text"
              placeholder=""
              value={note}
              onChange={(val) => setnote(val.target.value)}
            />
          </div>
        </div>
        <div className="hidden md:flex w-2/12 justify-center items-center">
          <button
            className={
              items.length < 1
                ? "w-2/3 h-20 bg-blue-300 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded mt-8"
                :"w-2/3 h-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            }
            disabled={items.length < 1 ? true : false}
            onClick={() => {
              if (items.filter((lis) => lis.item_qty > 0).length < 1) {
                setitems(items.filter((lis) => lis.item_qty > 0));
                alert("البضاعة المطلوبة غير موجودة في الفاتورة");
              } else {
                if(stopsell){

                }else{
                  if (
                    window.confirm(
                      "هل أنت متأكد أنك تبيع؟"
                    ) == true
                  ) {
                  setitems(items.filter((lis) => lis.item_qty > 0));
                  createaction(note, initState.token.id, items)(recaller)(
                    setrecaller
                  )(initDispatch)(clear)(printit);
                  }
                }
              }
            }}
          >
            بيع
          </button>
        </div>
          </div>
        <div className="md:hidden w-full md:w-9/12 mr-0 md:mr-10 mt-1 rounded-md p-2 flex">
        <button
            className={
              items.length < 1
                ? "w-full h-16 bg-blue-300 mr-0 hover:bg-blue-300 text-white font-bold rounded"
                :"w-full h-16 bg-blue-500 mr-0 hover:bg-blue-700 text-white font-bold rounded "
            }
            disabled={items.length < 1 ? true : false}
            onClick={() => {
              if (items.filter((lis) => lis.item_qty > 0).length < 1) {
                setitems(items.filter((lis) => lis.item_qty > 0));
                alert("البضاعة المطلوبة غير موجودة في الفاتورة");
              } else {
                if(stopsell){

                }else{
                  if (
                    window.confirm(
                      "هل أنت متأكد أنك تبيع؟"
                    ) == true
                  ) {
                  setitems(items.filter((lis) => lis.item_qty > 0));
                  createaction(note, initState.token.id, items)(recaller)(
                    setrecaller
                  )(initDispatch)(clear)(printit);
                  }
                }
              }
            }}
          >
            بيع
          </button>
        </div>
      {items.length > 0 ? (
        <div className="container mx-auto px-4 sm:px-8">
          <div className="py-8">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        اسم الصنف
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        باڕکۆد
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        سعر
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      عدد
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        تخفيظ
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        مجموع السعر
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        حذف
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((lis, index) => (
                      <tr key={index}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.item_name}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.item_barcode}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <input
                            className={
                              "appearance-none block w-full bg-gray-100 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            }
                            id="grid-last-name"
                            type="number"
                            placeholder="3.14"
                            value={lis.item_sell_price}
                            onChange={(val) => {
                              setitems(
                                items.map((lis, indexx) =>
                                  index == indexx
                                    ? {
                                        item_id: lis.item_id,
                                        item_name: lis.item_name,
                                        item_barcode: lis.item_barcode,
                                        item_price: lis.item_price,
                                        item_sell_price: val.target.value,
                                        item_qty: lis.item_qty,
                                        item_quantity: lis.item_quantity,
                                        item_min_sell_price:lis.item_min_sell_price
                                      }
                                    : lis
                                )
                              );
                              // setstopsell(false);
                            }}
                            onBlur={()=>{
                              if(Number(lis.item_sell_price)<Number(lis.item_min_sell_price)){
                                alert('لا ينبغي أن تكون هذه السلعة في '+numberbeautify(lis.item_min_sell_price)+'ينبغي بيع أقل');
                                // setstopsell(true);
                              }
                            }}
                            disabled
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <input
                            className={
                              "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            }
                            id="grid-last-name"
                            type="number"
                            placeholder="3.14"
                            value={lis.item_qty}
                            onChange={(val) => {
                              if (
                                Number(val.target.value) <=
                                Number(lis.item_quantity)
                              ) {
                                setitems(
                                  items.map((lis, indexx) =>
                                    index == indexx
                                      ? {
                                          item_id: lis.item_id,
                                          item_name: lis.item_name,
                                          item_barcode: lis.item_barcode,
                                          item_price: lis.item_price,
                                          item_sell_price: lis.item_sell_price,
                                          item_qty: val.target.value,
                                          item_quantity: lis.item_quantity,
                                          item_min_sell_price:lis.item_min_sell_price
                                        }
                                      : lis
                                  )
                                );
                              } else {
                                alert("لا توجد وحدات كافية في المخزون في هذه المرحلة");
                              }
                            }}
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <input
                            className={
                              initState.discount_attemp>0?
                              "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500":
                              "appearance-none block w-full bg-gray-100 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            }
                            id="grid-last-name"
                            type="number"
                            placeholder="3.14"
                            disabled={initState.discount_attemp>0?false:true}
                            onChange={(val) => {
                              var disc= (val.target.value);
                              if(disc>initState.discount_range){
                                alert(`مبلغ الخصم المسموح به  (${initState.discount_range})%`);
                                disc=0;
                              }else{
                                  var price=items.filter((lis,inde)=>inde==index)[0].item_price;
                                  var disccc=(price*disc)/100;
                                  var dd=disccc%250;
                                  if(dd>=125){
                                    disccc+=250-dd;
                                  }else{
                                    disccc-=dd;
                                  }
                                  var last =price-disccc;
                                  if(disc>0){
                                    setitems(
                                        items.map((lis, indexx) =>
                                          index == indexx
                                            ? {
                                                item_id: lis.item_id,
                                                item_name: lis.item_name,
                                                item_barcode: lis.item_barcode,
                                                item_price: lis.item_price,
                                                item_sell_price:last,
                                                item_qty: lis.item_qty,
                                                item_quantity: lis.item_quantity,
                                                item_min_sell_price:lis.item_min_sell_price
                                              }
                                            : lis
                                        )
                                      );
                                  }else{
                                    setitems(
                                        items.map((lis, indexx) =>
                                          index == indexx
                                            ? {
                                                item_id: lis.item_id,
                                                item_name: lis.item_name,
                                                item_barcode: lis.item_barcode,
                                                item_price: lis.item_price,
                                                item_sell_price: lis.item_price,
                                                item_qty: lis.item_qty,
                                                item_quantity: lis.item_quantity,
                                                item_min_sell_price:lis.item_min_sell_price
                                              }
                                            : lis
                                        )
                                      );
                                  }
                              }
                            }}
                          />
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {numberbeautify(
                            Number(lis.item_qty) * Number(lis.item_sell_price)
                          )}
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            <MdDeleteForever
                              size={28}
                              color="#e74c3c"
                              className="cursor-pointer"
                              onClick={() => {
                                setitems(
                                  items.filter(
                                    (list) => list.item_id != lis.item_id
                                  )
                                );
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider"
                        colSpan={3}
                      >
مجموع الكلي                      </th>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                        {numberbeautify(
                          items.reduce(
                            (a, v) =>
                              (a =
                                Number(a) +
                                Number(v.item_sell_price) * Number(v.item_qty)),
                            0
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="w-full h-20 flex justify-right items-center text-3xl border-b-2 px-5">
      قائمة المبيعات
      </div>
      <div className="w-full flex">
        <div className="w-full md:w-1/4 px-3 my-2">
          <label
            className={
              error
                ? "block uppercase tracking-wide text-red-700 text-xs font-bold mb-2"
                : "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            }
            for="grid-last-name"
          >
            تاريخ{" "}
          </label>
          <input
            className={
              error
                ? "appearance-none block w-full bg-gray-50 text-red-700 border border-red-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                : "appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
            id="grid-last-name"
            type="date"
            placeholder="3.14"
            value={from}
            onChange={(val) => setfrom(val.target.value)}
            onFocus={() => seterror(false)}
          />
        </div>
        <div className="w-full md:w-1/4 px-3 mt-1">
          <button
            className={
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8"
            }
            onClick={() => {
              if (!from || from == "") {
                seterror(true);
                return false;
              }
              getinvoiceaction(from)(recaller)(setrecaller)(initDispatch)(
                clear
              );
            }}
          >
            بحث
          </button>
        </div>
      </div>
      {initState.today_invoice.length > 0 ? (
        <div className="container mx-auto px-4 sm:px-8">
          <div className="">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        رقم الوصل
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      عدد                      
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        مجموع الكلي
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      مجموع سعر الكل
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      ملاحظة
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      یوزەر
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {initState.today_invoice.map((lis, index) => (
                      <tr
                        key={index}
                        className="cursor-pointer"
                        onClick={() => {
                          setinvoice_detail(lis);
                          setshow(true);
                        }}
                      >
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.sell_invoice_id}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {numberbeautify(
                              lis.items.reduce(
                                (a, v) => (a = Number(a) + Number(v.item_qty)),
                                0
                              )
                            )}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {numberbeautify(lis.items.reduce(
                                (a, v) => (a = Number(a) + (Number(v.item_qty)*Number(v.item_price))),
                                0
                              ))}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                          {numberbeautify(lis.items.reduce(
                                (a, v) => (a = Number(a) + (Number(v.item_qty)*Number(v.item_sell_price))),
                                0
                              ))}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.sell_invoice_note}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {lis.user_name}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      </div>
    </div>
  );
};

export default Sell;
