import { useaxios } from "../../../utils/useaxios";
export default (category_name, category_id) => recaller => setrecaller => initDispatch => clear => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    initDispatch({ type: 'loadingTrue' });
    useaxios('category/update.php', { category_name, category_id }).then((res) => {
        if (res.status == "ok") {
            setrecaller(recaller + 1);
        } else {
            clear();
        }
        // setloading(false);
    });
}