import { useaxios } from "../../../utils/useaxios";
export default (from, to) => recaller => setrecaller => initDispatch => clear => {
    var today = new Date();
    today = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    // initDispatch({ type: 'loadingTrue' });
    useaxios('damage/read.php', { from, to }).then((res) => {
        if (res.status == "ok") {
            initDispatch({ type: 'setbroke', payload: res.data });
        }
        // setloading(false);
    });
}