import React, { useState, useContext } from "react";
import { GlobalContext } from "../../context/provider";
import deleteaction from "../../context/action/sell/deleteaction";
import createaction from "../../context/action/return/createaction";
import { BiTrash } from "react-icons/bi";
import getitemaction from "../../context/action/buy/getitemaction";

const Sellinvoicedetailmodal = (props) => {
  const { initState, recaller, setrecaller, clear, initDispatch } =
    useContext(GlobalContext);

  const [barcode, setbarcode] = useState();
  const [items, setitems] = useState([]);
  return (
    <>
      {props.show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto w-full md:w-3/4 lg:w-3/4">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex flex-row justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl w-full uppercase font-semibold">
                  تغير
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      props.setshow(false);
                      setitems([]);
                      props.setinvoice_detail();
                    }}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      X
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative h-[75vh] overflow-y-auto p-6 flex-auto">
                  <div className="flex flex-wrap w-full">
                    <div className="w-full md:w-full px-3 my-2">
                      <label
                        className={
                          "block uppercase tracking-wide text-white text-xs font-bold mb-2"
                        }
                        for="grid-last-name"
                      >
                        اسم الصنف / باڕکۆد
                      </label>
                      <input
                        className={
                          "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        }
                        id="grid-last-name"
                        type="text"
                        placeholder="3.14"
                        value={barcode}
                        onChange={(val) => setbarcode(val.target.value)}
                        autoFocus
                        onKeyDown={(key) => {
                          if (key.key == "Enter") {
                            if(props.invoice_detail.items.filter((rs)=>rs.item_barcode===barcode).length>0){
                              var t1=props.invoice_detail.items.map((list)=>list.item_barcode===barcode?{sell_invoice_id:list.sell_invoice_id,sell_invoice_item_id:list.sell_invoice_item_id,item_id:list.item_id,item_price:list.item_price,item_qty:(Number(list.item_qty)+1),sell_invoice_date:list.sell_invoice_date,item_sell_price:list.item_sell_price,item_name:list.item_name,item_barcode:list.item_barcode}:list)
                              var result ={
                                sell_invoice_id:props.invoice_detail.sell_invoice_id,
                                sell_invoice_date:props.invoice_detail.sell_invoice_date,
                                sell_invoice_note:props.invoice_detail.sell_invoice_note,
                                sell_invoice_price:props.invoice_detail.sell_invoice_price,
                                sell_invoice_sold_price:props.invoice_detail.sell_invoice_sold_price,
                                items:t1
                              }
                              props.setinvoice_detail(result);
                            }else{
                              getitemaction(barcode)(recaller)(setrecaller)(items)(setitems)(setbarcode)
                            }
                            setbarcode('');
                            // getitemaction(barcode)(recaller)(setrecaller)(
                            //   items
                            // )(setitems)(setbarcode)(props.invoice_detail.items);
                          }
                        }}
                      />
                    </div>
                    {items.length > 0 ? (
                      <div className="w-full mb-3">
                        <div className="py-1">إضافة البضائع إلى الإيصالات</div>
                        <table className="min-w-full leading-normal">
                          <thead>
                            <tr>
                              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              اسم الصنف
                              </th>
                              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                باڕکۆد
                              </th>
                              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              عدد
                              </th>
                              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              سعر
                              </th>
                              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              يزيد
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((lis, index) => (
                              <tr key={index}>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    {lis.item_name}
                                  </p>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    {lis.item_barcode}
                                  </p>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                  <input
                                    className={
                                      "appearance-none block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    }
                                    id="grid-last-name"
                                    type="number"
                                    placeholder="3.14"
                                    value={lis.item_qty}
                                    onChange={(val) => {
                                        setitems(
                                          items.map((rs, indexx) =>
                                            lis.item_id == rs.item_id
                                              ? {
                                                  item_id: rs.item_id,
                                                  item_name: rs.item_name,
                                                  item_barcode: rs.item_barcode,
                                                  item_sell_price:
                                                    rs.item_sell_price,
                                                  item_return_price:
                                                    rs.item_return_price,
                                                  item_qty: val.target.value,
                                                }
                                              : rs
                                          )
                                        );
                                    }}
                                  />
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                  <input
                                    className={
                                      "appearance-none block w-full bg-gray-100 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    }
                                    id="grid-last-name"
                                    type="number"
                                    placeholder="3.14"
                                    value={lis.item_sell_price * lis.item_qty}
                                    disabled
                                    onChange={(val) => {
                                      setitems(
                                        items.map((rs, indexx) =>
                                          lis.item_id == rs.item_id
                                            ? {
                                                item_id: rs.item_id,
                                                item_name: rs.item_name,
                                                item_barcode: rs.item_barcode,
                                                item_sell_price:
                                                  rs.item_sell_price,
                                                item_return_price:
                                                  val.target.value,
                                                item_qty: rs.item_qty,
                                              }
                                            : rs
                                        )
                                      );
                                    }}
                                  />
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <button
                                  className={
                                    "bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  }
                                  type="button"
                                  onClick={() => {
                                    var t1=props.invoice_detail.items;
                                    for(var i=0;i<items.length;i++){
                                        var temp={
                                          item_barcode:items[i].item_barcode,
                                          item_id:items[i].item_id,
                                          item_name:items[i].item_name,
                                          item_price:items[i].item_price,
                                          item_qty:items[i].item_qty,
                                          item_sell_price:items[i].item_price,
                                          sell_invoice_date:t1[0].sell_invoice_date,
                                          sell_invoice_id:t1[0].sell_invoice_id,
                                          sell_invoice_item_id:t1[0].sell_invoice_item_id
                                        }
                                        t1.push(temp)
                                    }
                                    var result ={
                                      sell_invoice_id:props.invoice_detail.sell_invoice_id,
                                      sell_invoice_date:props.invoice_detail.sell_invoice_date,
                                      sell_invoice_note:props.invoice_detail.sell_invoice_note,
                                      sell_invoice_price:props.invoice_detail.sell_invoice_price,
                                      sell_invoice_sold_price:props.invoice_detail.sell_invoice_sold_price,
                                      items:t1
                                    }
                                    props.setinvoice_detail(result);
                                    setitems([]);
                                  }}
                                >
                                  يزيد
                                </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                    <table className="min-w-full leading-normal">
                      <thead>
                        <tr>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          اسم صنف
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            باڕکۆد
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          عدد المبيع
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          سعر صنف
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          سعر المبيع
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          حذف
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.invoice_detail.items.map((lis, index) => (
                          lis.item_qty>0?
                          <tr key={index}>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {lis.item_name}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {lis.item_barcode}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {lis.item_qty}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {lis.item_price}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {lis.item_sell_price * lis.item_qty}
                              </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <BiTrash color="red" size={20} onClick={()=>{
                                // console.log(props.invoice_detail);
                                var t1=t1?t1:props.invoice_detail.items;
                                var filterd=t1.filter((list)=>list.item_id===lis.item_id)[0];
                                if(filterd?.item_qty>1){
                                  t1=t1.map((list)=>list.item_id===lis.item_id?{sell_invoice_id:list.sell_invoice_id,sell_invoice_item_id:list.sell_invoice_item_id,item_id:list.item_id,item_price:list.item_price,item_qty:(Number(list.item_qty)-1),sell_invoice_date:list.sell_invoice_date,item_sell_price:list.item_sell_price,item_name:list.item_name,item_barcode:list.item_barcode}:list)
                                }else{
                                  t1=t1.filter((list)=>list.item_id!=lis.item_id)
                                }
                                var result ={
                                  sell_invoice_id:props.invoice_detail.sell_invoice_id,
                                  sell_invoice_date:props.invoice_detail.sell_invoice_date,
                                  sell_invoice_note:props.invoice_detail.sell_invoice_note,
                                  sell_invoice_price:props.invoice_detail.sell_invoice_price,
                                  sell_invoice_sold_price:props.invoice_detail.sell_invoice_sold_price,
                                  items:t1
                                }
                                props.setinvoice_detail(result);
                                // props.setinvoice_detail()
                              }} />
                            </td>
                          </tr>:null
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  {/* <button
                    className="bg-slate-600 text-white active:bg-slate-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      props.printit(props.invoice_detail.sell_invoice_id);
                    }}
                  >
                    پرینت
                  </button> */}
                  <button
                    className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (
                        window.confirm("إشعار الحذف. هل أنت متأكد؟") == true
                      ) {
                        deleteaction(props.invoice_detail.sell_invoice_id)(
                          recaller
                        )(setrecaller)(initDispatch)(clear);
                      }
                    }}
                  >
                    حذف الوصل
                  </button>
                  <button
                    className={
                      "bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    }
                    type="button"
                    onClick={() => {
                      createaction(
                        props.invoice_detail.sell_invoice_id,
                        "",
                        props.invoice_detail.items,
                        initState.token.id
                      )(recaller)(setrecaller)(initDispatch)(clear);
                    }}
                  >
                    تغير الوصل
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Sellinvoicedetailmodal;
